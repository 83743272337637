:local(.scaffolding) {
  composes: space-col from global;
  width: 100%;
}

:local(.summary) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.summary_description) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.summary_controls) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.summary_controls_withNoFrame > *:last-child) {
  margin-right: 0 !important;
}

:local(.summary_disclaimer) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}

:local(.summary_isSpaceLimited) {
  composes: space-col-reverse from global;
}

:local(.summary_isSpaceLimited > *:last-child) {
  margin-bottom: 25px;
}
