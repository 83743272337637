:local(.receiptPay) {

}

:local(.receiptPay_itemisedList) {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--background);
}

:local(.receiptPay_itemisedList:last-child) {
  border-bottom: none;
}

:local(.receiptPay_scaffold) {
  composes: space-col from global;
  flex-grow: 1;
}
