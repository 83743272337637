:local(.motorweb) {
  composes: space-row from global;
  composes: base from global;
  composes: inside from global;
  align-items: flex-end;
}

:local(.motorDetails){
  width:100%;
}
