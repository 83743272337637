:local(.scaffolding) {
  composes: space-col from global;
  width: 100%;
}

:local(.contentScaffold) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
}

:local(.button) {
  font-size: 14px;
  min-height: 30px;
  height: 30px;
  padding: 0 10px;
  min-width: 60px;
  width: 130px;
}

:local(.summaryActions_discard) {
  flex-grow: 1;
}

:local(.summaryActions_save) {
  flex-grow: 1;
}

:local(.summaryActions_complete) {
  flex-grow: 3;
}

:local(.buttonWrapper) {
  composes: space-row from global;
  composes: large from global;
  composes: inside from global;
}
