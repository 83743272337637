:local(.scaffold) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
}

:local(.carDetails) {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
}

:local(.carDetails_isQuerying) {
  left: -20px;
}
