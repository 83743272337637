:local(.footer) {
  width: 100%;
  background: var(--app-footer, black);
  color: var(--app-footer-on, white);
  display: flex;
  justify-content: center;
}

:local(.footer_view_isSpacious) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  justify-content: space-between;
}

:local(.footer_view_isSpaceLimited) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
}

:local(.footer_view_statement) {
  display: flex;
  flex-grow: 1;
  font-size: 12px;
}

:local(.footer_view_isSpacious .footer_view_statement) {
  order: 0;
  max-width: 75%;
}

:local(.footer_view_isSpaceLimited .footer_view_statement) {
  order: 1;
  justify-content: center;
  text-align: center;
}

:local(.footer_view_links) {
  composes: space-row from global;
  composes: large from global;
  composes: inside from global;
  justify-content: center;
}

:local(.footer_view_isSpacious .footer_view_links) {
  order: 1;
}

:local(.footer_view_isSpaceLimited .footer_view_links) {
  order: 0;
  margin-bottom: var(--space-large);
  flex-wrap: wrap;
}

:local(.footer_view_links_label) {
  color: var(--app-footer-on);
  text-decoration: none;
}

:local(.footer_view_statement a) {
  color: var(--surface, white);
  font-weight: 800;
}

:local(.footer_view_links_image) {
}

:local(.footer_view_links_icon) {
  display: flex;
  align-items: center;
  font-size: 30px;
  transition: color var(--app-transition);
}

:local(.footer_view_links_icon:hover) {
  color: var(--trigger-hover);
  transition: color var(--app-transition);
}
