:local(.itemArrayForm) {
  display: initial;
}

:local(.itemArrayForm_form_wrapper) {
  position: relative;
}

:local(.itemArrayForm_form_wrapper:before) {
  position: absolute;
  content: '';
  left: 0;
  top: var(--space-default);
  width: 5px;
  height: calc(100% - var(--space-large));
  background-color: var(--secondary);
  opacity: 0.5;
}

:local(.itemArrayForm_form) {
  position: relative;
  width: 100%;
}

:local(.itemArrayForm_form_buttons) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

:local(.itemArrayForm_form_buttons_wide) {
  composes: space-col from global;
  max-width: 100%;
  justify-content: initial;
}

:local(.itemArrayForm_form_button) {
  max-width: 212px;
  font-size: var(--font-size-body);
  width: 100%;
}

:local(.itemArrayForm_form_link) {
  display: block;
  font-size: var(--font-size-body);
  padding: 0 var(--space-large, 14px);
  font-weight: bold;
}

:local(.itemArrayForm_form_button_wide) {
  max-width: 100%;
  justify-content: initial;
}

:local(.itemArrayForm_add_button) {
  max-width: 212px;
  font-size: var(--font-size-body);
  width: 100%;
  margin-left: auto;
}

:local(.itemArrayForm_form_button_wide) {
  max-width: 100%;
  margin-bottom: var(--space-default);
}

:local(.itemArrayForm_form_button_wide:last-child) {
  margin-bottom: 0;
}

/* Claim Display */

:local(.itemArrayForm_element) {
  border: 1px solid var(--input-border-color-special, #ccc);
  box-sizing: border-box;
  border-radius: 3px;
  padding: var(--space-default) var(--space-default);
  margin-bottom: var(--space-default);
}

:local(.description_list) {
  position: relative;
  display: grid;
  grid-template-columns: 30% auto;
  grid-row-gap: 8px;
  grid-column-gap: var(--space-default, 10px);
  margin: 0;
}

:local(.description_list__mobile) {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  margin: 0;
}

:local(.description_list dt) {
  position: relative;
  text-align: left;
  font-weight: bold;
  margin: 0;
}

:local(.description_list dd) {
  position: relative;
  margin: 0;
}

:local(.itemArrayForm_element_footer) {
  padding-top: var(--space-default);
  text-align: left;
}

:local(.itemArrayForm_element_footer_link) {
  padding-right: var(--space-default);
  font-size: 14px;
}
:local(.itemArrayForm_element_footer_link:last-child) {
  padding-right: 0;
}

