:local(.selector) {
  border-radius: var(--surface-corners);
  border: 1px solid var(--surface-trim);
}

:local(.selector_isScrollable) {
  max-height: 300px;
  overflow-y: scroll;
}

:local(.selector_option) {
  composes: space-row from global;
  composes: base from global;
  composes: frame from global;
  cursor: pointer;
  border-bottom: 1px solid var(--surface-trim);
}

:local(.selector_option:hover) {
  background: var(--trigger);
  color: var(--trigger-on);
}
