:local(.description) {
  text-align: center;
}

:local(.divider) {
  font-size: var(--trigger-font-size, '18px');
  text-align: center;
  font-weight: 500;
}

:local(.separated) {
  margin-top: 5px;
}

:local(.form > *:last-child) {
  margin-bottom: 0 !important;
}

:local(.helpLink) {
  padding-top: 5px;
  font-size: 13px;
}

:local(.centered) {
  text-align: center;
}

@media screen and (max-width: 800px) {
  :local(.emailOneTimeLink) {
      font-size: 0.9em;
  }
}

