:local(.pageCover) {
  width: 100%;
  height: 100%;
  background-position: bottom right;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

:local(.pageCover_mobile) {
  background-size: 50%;
}
