:local(.container) {
    box-shadow: 0 0 60px #efe1c9;
    max-width: 50rem;
    padding: 3rem;
    margin: 5rem 0;
    border-radius: var(--surface-corners);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

:local(.cta) {
    font-size: 1.3rem;
    padding: min(1.5rem, 3vw);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

:local(.recaptcha_print) {
    margin-top: 20px;
}

:local(.recaptcha_print p) {
    font-size: 0.9em;
}

@media screen and (max-width: 800px) {
    :local(.container) {
        padding: 1rem;
        margin: 0;
        box-shadow: none;
    }
}
