:local(.expiredOrConverted) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
  padding-top: var(--space-large);
}

:local(.expiredOrConverted_reason) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}

:local(.expiredOrConverted_help) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}

:local(.expiredOrConverted_trigger) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}
