.switch {
  width: 48px;
  min-width: 48px;
  height: 24px;
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  background: var(--input-aside);
}

.switch.on {
  background: var(--highlight);
}

.switch.on .switch-toggle {
  left: 24px;
  border: 2px solid var(--highlight);
}

.switch .switch-toggle {
  border: 2px solid var(--input-aside);
  width: 24px;
  height: 24px;
  left: 0;
  border-radius: 50%;
  background: var(--surface);
  position: relative;
  transition: left .2s ease-in-out;
}
