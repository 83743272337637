:local(.bnpNavigation) {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  border-bottom: var(--input-border-thickness, 1px) solid var(--input);
  margin-bottom: var(--space-small);
}

:local(.bnpNavigationItem) {
  display: block;
  position: relative;
  padding: var(--space-default);
}

:local(.bnpNavigationItem)::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  display: block;
  width: 20px;
  height: 20px;
  background: var(--input-aside);
  border-radius: 20px;
}

:local(.bnpNavigationActive) {
  color: var(--trigger, white);
  font-weight: 500;
}

:local(.bnpNavigationActive)::after {
  background: var(--input-on);
}

:local(.bnpNavigationComplete)::after {
  background: var(--input-on);
}

@media only screen and (max-width: 600px) {
  :local(.bnpNavigation) {
    flex-direction: column;
    border-bottom: none;
    border-left: var(--input-border-thickness, 1px) solid var(--input);
    margin-left: var(--space-default);
  }

  :local(.bnpNavigationItem) {
    padding-left: var(--space-large);
  }

  :local(.bnpNavigationItem)::after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: auto;
    left: 0;
    margin-top: -10px;
  }
}

/*
  @media only screen and (max-width: 600px) {
    padding: 5px;
    height: 100%;
  }
*/
