:local(.collection) {
  composes: space-col from global;
  composes: small from global;
  composes: inside from global;
}

:local(.collection_existingItem) {
  composes: space-row from global;
}

:local(.collection_item) {
  composes: space-row from global;
  composes: center-vertical from global;
  composes: base from global;
  composes: frame from global;
  justify-content: space-between;
  height: 50px;
  cursor: pointer;
  transition: background var(--app-transition);
}

:local(.collection_itemHook:first-child) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

:local(.collection_item:hover) {
  background: var(--trigger);
  color: var(--trigger-on);
  transition: background var(--app-transition), color var(--trigger-on);
}

:local(.collection_item:hover .collection_item_add) {
  color: var(--trigger-on);
  transition: color var(--trigger-on);
}

:local(.collection_item_add) {
  color: var(--trigger);
  transition: color var(--trigger-on);
}

:local(.collection_item_update) {
  color: var(--trigger);
  transition: color var(--trigger-on);
}

:local(.collection_item:hover .collection_item_update) {
  color: var(--trigger-on);
  transition: color var(--trigger-on);
}

:local(.collection_item_delete) {
  composes: space-row from global;
  composes: center from global;
  color: var(--app-error);
  background-color: var(--input);
  width: var(--input-height, 40px);
  margin-left: 5px;
  cursor: pointer;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: var(--surface-corners);
  border-bottom-right-radius: var(--surface-corners);
}

:local(.collection_item_delete:hover) {
  background: var(--trigger);
  transition: background var(--app-transition);
}

:local(.collection_item_delete:hover .collection_item_delete_icon) {
  color: var(--trigger-on);
  transition: color var(--app-transition);
}
