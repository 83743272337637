:local(.badge) {
  height: 24px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
  border-radius: var(--surface-corners, 3px);
  background-color: var(--display-on, black);
}

:local(.badge label) {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 12px;
  color: var(--display, honeydew);
}
