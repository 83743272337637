:local(.appDisplay) {
  composes: space-row from global;
  composes: center from global;
  width: 100%;
  min-height: 220px;
  padding: var(--space-large, 15px) 0;
  background-color: var(--display, honeydew);
  color: var(--display-on, black);
  transition: height var(--app-transition), min-height var(--app-transition);
}

:local .appDisplay :global .blade-loader {
  color: var(--display-on, black)!important;
}

:local(.sticky) {
  height: var(--app-header-height, 50px);
  min-height: var(--app-header-height, 50px);
  transition: height var(--app-transition), min-height var(--app-transition);
}

:local(.isStickyMode) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  height: var(--app-header-height, 50px);
  min-height: var(--app-header-height, 50px);
  transition: top 2s ease;
}

:local(.appDisplay_dummy) {
  min-height: 220px;
  opacity: 0;
}

:local(.display_compact){
  padding: 0px;
  min-height: 0px;
  width: 85%;
}
