:local(.appBar) {
  composes: space-row from global;
  composes: center-horizontal from global;
  background-color: #aea091;
  color: var(--app-bar-on, white);
  height: 120px;
  position: relative;
}

:local(.appBar_view) {
  width: 100%;
  composes: space-col from global;
  max-width: 1200px!important;
}

:local(.appBar_phone) {
  height: 30px;
  padding: 0 var(--space-large);
  display: flex;
  justify-content: flex-end;
}

:local(.appBarContent) {
  composes: space-row from global;
  composes: center-vertical from global;
  composes: large from global;
  composes: frame from global;
  justify-content: space-between;
  height: inherit;
}

:local(.appBarContent img) {
  width: 349px!important;
}

@media only screen and (max-width: 540px) {
  :local(.appBarContent img) {
    width: 150px!important;
  }
}

:local(.appBarContent > *) {
  color: var(--app-bar-on, white);
}

:local(.appBarContent svg) {
  font-size: 55px;
  padding: 15px;
}

:local(.appBarContent_right) {
  composes: space-col from global;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 50%;
  height: 100%;
}

:local(.appBarContent_right_row:last-child) {
  margin-top: 15px;
}

:local(.appBarContent_right_row) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

:local(.appBarContent_right_row__condensed) {
  justify-content: flex-end;
}

:local(.appBarContent_link) {
  color: #fff;
}

:local(.appBarContent_right_row_phone) {
  display: flex;
  align-items: center;
  font-weight: bold;
}

:local(.appBarContent_right_row_phone_custom) {
  margin-left: 3px;
  padding-right: 0;
}

:local(.appBarContent_right_row_phone_custom a) {
  font-size: 14px!important;
}

:local(.appBarContent_right_row_burger) {
  border: 1px solid var(--trigger-on);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px!important;
  height: 40px!important;
  min-height: 40px!important;
}

:local(.appBarContent_right_row_burger:hover) {
  color: var(--trigger);
  background: var(--trigger-on)!important;
}

:local(.appBarContent_right_row_button) {
  border: 1px solid var(--trigger-on);
  height: 30px!important;
  width: auto !important;
  min-height: 30px!important;
  font-size: 14px!important;
  padding: 3px 18px;
  font-weight: normal;
}

:local(.appBarContent_right_row_button:hover) {
  color: var(--trigger);
  background: var(--trigger-on)!important;
}

:local(.appBarContent_drawer) {
  position: absolute;
  width: 100%;
  height: 0px;
  overflow: hidden;
  background: #aea091;
  top: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: height 0.2s ease-out;
  z-index: 9999999;
}

:local(.appBarContent_drawer__isOpen) {
  height: 150px;
}

:local(.appBarContent_drawer_row) {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}