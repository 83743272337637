:local(.view) {
  width: 100%;
  height: 100%;
  align-self: center;
}

:local(.view_xs) {
  max-width: var(--app-view-xs, 500px);
}

:local(.view_s) {
  max-width: var(--app-view-s, 768px);
}

:local(.view_m) {
  max-width: var(--app-view-m, 992px);
}

:local(.view_l) {
  max-width: var(--app-view-l, 1024px);
}

:local(.view_xl) {
  max-width: var(--app-view-xl, 100%);
}
