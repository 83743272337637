:local(.referred) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
  padding-top: var(--space-large);
}

:local(.referred_subTitle) {
  padding: var(--space-small, 10px) var(--space-large, 15px)
    var(--space-small, 10px) var(--space-large, 15px);
}

:local(.referred_bodyContent) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}

:local(.referred_trigger) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}
