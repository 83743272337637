:local(.segmentControl) {
  composes: space-row from global;
}
:local(.segmentControl_mobile) {
  composes: space-col from global;
}

:local(.segmentControl_mobile > .segmentControl_item:first-child) {
  border-top-left-radius: var(--input-corners, 3px);
  border-top-right-radius: var(--input-corners, 3px);
}

:local(.segmentControl_mobile > .segmentControl_item:not(:first-child)) {
  border-top: 0;
}

:local(.segmentControl_mobile > .segmentControl_item:last-child) {
  border-bottom-left-radius: var(--input-corners, 3px);
  border-bottom-right-radius: var(--input-corners, 3px);
}

:local(.segmentControl_mobile > .segmentControl_item) {
  padding: 11px;
  border-radius: 0;
}

:local(.segmentControl > *:not(:first-child)) {
  border-radius: 0;
  border-left: 0;
}

:local(.segmentControl > *:first-child) {
  border-top-left-radius: var(--input-corners, 3px);
  border-bottom-left-radius: var(--input-corners, 3px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:local(.segmentControl > *:last-child) {
  border-top-right-radius: var(--input-corners, 3px);
  border-bottom-right-radius: var(--input-corners, 3px);
}

:local(.segmentControl_item) {
  composes: space-row from global;
  composes: center from global;
  border: var(--input-border-thickness, 1px) solid;
  border-color: var(--input-border-color, --input);
  border-radius: var(--input-corners, 3px);
  color: var(--input-on);
  cursor: pointer;
  min-height: var(--input-height, 60px);
  text-align: center;
  height: auto !important;
  padding: 15px;
}

:local(.segmentedControl_item_isInline) {
  flex-basis: 27%;
}

:local(.segmentControl_item_selected) {
  background-color: var(--highlight);
  color: var(--highlight-on, white);
}
