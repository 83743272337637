:local(.paymentPlansLabel) {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  padding: 0 0 var(--space-default, 10px);
}

:local(.paymentPlansDescription) {
  padding: 0 0 var(--space-default, 10px);
}


:local(.paymentPlanCards) * {
  box-sizing: border-box;
}

:local(.paymentPlanCards) {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: var(--space-default, 10px);
}

:local(.paymentPlanCard) {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid var(--input-on);
  border-radius: var(--input-corners, 0);
}

:local(.paymentPlanCard).solid {
  background-color: var(--input);
}

:local(.paymentPlanCard).border {
  background-color: var(--surface);
}

:local(.paymentPlanCard):hover {
  border: 2px solid var(--highlight, --primary);
  transition: border 0.2s cubic-bezier(0.35, 0.13, 0.84, 0.42);
}

:local(.paymentPlanCardSelected) {
  border: 2px solid var(--highlight, --primary);
  cursor: default;
}

:local(.paymentPlanCard_title) {
  position: relative;
  color: var(--input-on, inherit);
  font-size: 2.2ex;
  padding: var(--space-default, 10px) var(--space-default, 15px);
  border-bottom: 1px solid var(--highlight, --primary);
}

:local(.paymentPlanCardSelected .paymentPlanCard_title) {
  background-color: var(--highlight, --primary);
  color: var(--highlight-on, --primary-on);
}

:local(.paymentPlanCard_checkMark) {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  margin-top: -15px;
  right: var(--space-default, 10px);
  fill: var(--highlight-on, --primary-on);
}

:local(.paymentPlanCard_body) {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: var(--space-default, 10px) var(--space-default, 10px);
}

:local(.paymentPlanCardSelected .paymentPlanCard_body):before {
  position: absolute;
  display: block;
  font-size: 1.4ex;
  top: var(--space-default, 10px);
  right: var(--space-default, 10px);
  content: attr(data-selected-text);
}

:local(.paymentPlanCard_body_price) {
  position: relative;
  font-size: 2.6ex;
  padding-right: 50px;
  padding-bottom: 5px;
}

:local(.paymentPlanCard_body_priceSmallPrint) {
  position: relative;
  font-size: 1.4ex;
  padding-bottom: 10px;
}

:local(.paymentPlanCard_body_spacer) {
  position: relative;
  display: block;
  flex-grow: 1;
}

:local(.paymentPlanCard_body_description) {
  position: relative;
}

@media (min-width: 768px) {
  :local(.paymentPlanCards) {
    flex-direction: row;
  }

  :local(.paymentPlanCard) {
    flex-basis: calc(50% - 10px);
  }
}


