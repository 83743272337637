:local(.scaffolding) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  width: 100%;
}

:local(.policyCancelSuccess) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
}
