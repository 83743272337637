:local(.cookies) {
  background-color: var(--primary-aside, grey);
  color: var(--primary-on, white)!important;
  font-size: 12px;
}

:local(.cookies_accept) {
  height: 25px;
  font-size: 12px;
  padding: 0 10px;
}
