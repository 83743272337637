:local(.mobile_select_wrap) {
  -webkit-appearance: none;
  position: relative;
}

:local(.mobile_select_wrap::before) {
  position: absolute;
  content: ' ';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  right: 10px;
  bottom: 17px;
}

:local(.mobile_select) {
  -webkit-appearance: none;
}
