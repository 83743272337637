:local(.toast) {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

:local(.toast_isVisible) {
  height: var(--app-header-height, 80px);
  min-height: var(--app-header-height, 80px);
  transition: height var(--app-transition);
}

:local(.toast_isHidden) {
  height: 0;
  min-height: 0;
  transition: height var(--app-transition);
}

:local(.toast_hideOverflow) {
  overflow: hidden;
}

:local(.toast_showOverflow) {
  overflow: visible;
}
