:local(.header) {
    display: flex;
    gap: 1rem;
    align-items: center;
}

:local(.header) h1 {
    font-size: min(6vw, 38px);
    font-weight: 600;
}

:local(.header):local(.noRego) h1 {
    text-align: center;
}

:local(.rego) {
    border: 1px solid #000;
    border-radius: 5px;
    padding: min(1rem, 2vw) min(0.5rem, 1vw);
    letter-spacing: min(0.5rem, 1vw);
    text-indent: 0.5rem;
    font-weight: 600;
    font-size: 1.2rem;

    /* "Screws" on the rego - in case it needs to be restored: */
    /* --circle: url("data:image/svg+xml;utf8,<svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'><circle cx='50' cy='50' r='50' /></svg>");
    background:
        no-repeat top 4px left 4px/4px var(--circle),
        no-repeat top 4px right 4px/4px var(--circle),
        no-repeat bottom 4px left 4px/4px var(--circle),
        no-repeat bottom 4px right 4px/4px var(--circle); */
}

@media screen and (max-width: 650px) {
    :local(.header) {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
}
