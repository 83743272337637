:local(.select) {
  composes: space-row from global;
  composes: center-vertical from global;
  width: 100%;
  background: var(--input);
  border-radius: var(--surface-corners);
}

:local(.select_wrap) {
  width: 100%;
  position: relative;
}

:local(.select_button) {
  box-sizing: border-box;
  outline: none;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: var(--input-height, 40px);
  padding: 0 10px;
  border: 0;
  border-radius: var(--input-corners);
  background: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

:local(.select_button:hover),
:local(.select_button:focus),
:local(.select_list_item):global(.highlighted) {
  outline: none;
  background: var(--trigger-hover);
  color: var(--trigger-on);
  transition: background var(--app-transition), color var(--app-transition);
}

:local(.select_button:hover .select_button_label),
:local(.select_button:focus .select_button_label) {
  color: var(--trigger-on);
  transition: color var(--app-transition);
}

:local(.select_placeholder) {
  opacity: .6;
}

:local(.select_button_label) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--input-on);
}

:local(.select_input_icon_wrap) {
  display: flex;
}

:local(.select_input_icon) {
  margin-right: 16px;
}

:local(.select_input_icon__last) {
  margin-right: 0;
}

:local(.select_list_wrap) {
  margin: 0;
  padding: 0;
  text-indent: 0;
  outline: none;
  list-style-type: none;
  position: absolute;
  z-index: 9;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  height: auto !important;
  width: 100%;
  max-height: 40ex;
  overflow-y: auto;
  background: var(--surface);
  box-shadow: 0px 1px 10px 1px var(--surface-trim);
  border-right: 1px solid var(--surface-trim);
  border-bottom: 1px solid var(--surface-trim);
  border-left: 1px solid var(--surface-trim);
  border-bottom-right-radius: var(--surface-corners);
  border-bottom-left-radius: var(--surface-corners);
}

:local(.select_list_item) {
  display: flex;
  align-items: center;
  min-height: 50px;
  word-wrap: break-word;
  user-select: none;
  background: var(--select-background, --surface);
  transition: background var(--app-transition);
  padding: var(--space-default);
  border-bottom: var(--select-item-border-bottom ,1px solid var(--background));
  color: var(--input-on);
}
