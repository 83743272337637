:local(.voucher_description) {
  font-size: 13px;
  margin-bottom: 0 !important;
}

:local(.voucher_fieldset) {
  display: flex;
}

:local(.voucher_button) {
  border-radius: var(--input-corners);
  max-width: 20%;
  margin: 0 5px;
  max-width: 150px;
}

:local(.voucher_button_wrap) {
  display: flex;
  min-width: 300px;
}

@media screen and (max-width: 400px) {
  :local(.voucher_button_wrap) {
   flex-direction: column;
   gap: 0.5rem;
   min-width: auto;
  }
}

:local(.voucher_button_wrap_applied) {
  min-width: auto;
}

@media (max-width: 992px) {
  :local(.voucher_fieldset) {
    flex-direction: column;
  }

  :local(.voucher_button_wrap) {
    justify-content: space-between;
    margin-top: 20px;
  }

  :local(.voucher_button) {
    margin: 0;
    max-width: 100%;
  }

  :local(.voucher_button:first-child) {
    margin-right: 5px !important;
  }
}

:local(.voucher_button:last-child) {
  margin-right: 0 !important;
}

:local(.voucher_input) {
  display: initial;
}

:local(.voucher_error) {
  color: var(--app-error);
  font-size: 14px !important;
}

:local(.voucher_terms) {
  font-size: 14px !important;
}

:local(.voucherFieldset) {
  position: relative;
}

:local(.voucher_container) {
  position: relative !important;
}
