:local(.navWrap_isOpen) {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}

:local(.navWrap_isOpen .appNav) {
  width: 260px;
}

:local(.navWrap_isClosed .appNav) {
  width: 0px;
  transition: all var(--app-transition);
}

:local(.appNav) {
  position: absolute;
  height: 100%;
  z-index: 10;
  background-color: var(--app-primary, grey);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:local(.appNav_isOpen) {
  right: 0;
  transition: right var(--app-transition);
}

:local(.appNav_isClosed) {
  right: -260px;
  transition: right var(--app-transition);
}

:local(.appNav_underlay) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

:local(.appNav_sections) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
}

:local(.appNav_section) {
  composes: space-col from global;
}

:local(.appNav_section h3) {
  color: var(--app-primary-on, black);
}

:local(.appNav_section_heading) {
  composes: space-col from global;
  composes: base from global;
  composes: frame from global;
}

:local(.appNav_navItem) {
  composes: space-row from global;
  composes: center-vertical from global;
  composes: base from global;
  composes: frame from global;
  composes: inside from global;
  cursor: pointer;
  color: var(--app-primary-on, black);
  transition: color var(--app-transition);
  border-bottom: 1px solid var(--app-primary-trim, grey);
}

:local(.appNav_navItem:hover) {
  background-color: var(--app-primary-aside, grey);
  transition: background-color var(--app-transition);
}

:local(.appNav_footer) {
  composes: space-row from global;
  composes: frame from global;
  composes: base from global;
}

:local(.appNav_navItem label) {
  flex-grow: 1;
}

:local(.appNav_version) {
  composes: space-row from global;
  composes: base from global;
  composes: frame from global;
  composes: center from global;
}

:local(.appNav_version small) {
  opacity: 0.5;
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: var(--app-primary-on, black);
  opacity: 0.5;
}

:local(.appNav_section_heading_smallText) {
  color: var(--app-primary-on, black);
  opacity: 0.5;
}
