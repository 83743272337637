:local(.adjustOverview) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
}

:local(.adjustOverview_description) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
}

:local(.adjustOverview_description_trimIsDivider) {
  border-bottom: 1px solid var(--surface-trim);
}

:local(.adjustOverview_action) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
}

:local(.recaptcha_print) {
  margin-top: 20px;
}