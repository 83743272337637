:local(.scaffolding) {
  composes: space-col from global;
  width: 100%;
}

:local(.controls) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.documents) {
  margin-bottom: 25px;
}
