:local(.legalHeader) {
    text-align: left;
    align-self: flex-start;
}

:local(.legal) {
    margin: 1rem 0 3rem 0;
    font-size: 0.9rem;
}

:local(.legal) p {
    margin-bottom: 0.5rem;
}
