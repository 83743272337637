:local(.banner) {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

:local(.banner_image) {
  background-size: cover;
  background-position: center;
}

:local(.banner_image h2) {
  color: white;
}

:local(.banner_noImage) {
  background-color: var(--primary);
  color: var(--primary-on);
}

:local(.banner h2) {
  font-size: 22px;
  font-weight: 900;
  text-align: center;
}

:local(.bannerToggle) {
  font-size: 20px;
  position: absolute;
  bottom: -12px;
}
