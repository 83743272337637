:local(.imagebox) {
  max-width: 100%;
  padding: 10px;
  border: 1px solid;
}
:local(.itemListParent) {
  position: relative;
}
:local(.itemList) {
  overflow-y: scroll;
  list-style: none;
  position: relative;
  z-index: 1000;
  width: 100%;
  padding: 0;
  margin: 0;
}
:local(.itemListOpen) {
  background: var(--surface-aside);

  margin-top: 5px;
  list-style: none;
  position: relative;
  z-index: 1000;
  width: 100%;
  background: var(--surface);

  border: 1px solid var(--surface-trim);
  border-radius: var(--surface-corners);
}

/* Only show as a "pop over" on desktop */
@media only screen and (min-width: 768px) {
  :local .itemListOpen {
    margin-top: 2px;
    position: absolute;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
  }
}

:local(.itemListItem) {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  word-break: break-word;
  width: 100%;
  user-select: none;
  background: var(--surface);
  transition: background var(--app-transition);
  min-height: var(--input-height, inherit);
  padding: 4px 10px;
  border-bottom: 1px solid var(--background);
}

:local .itemListItemHighlighted {
  background: var(--trigger, #bde4ff);
  color: var(--trigger-on, inherit);
}
