:local(.scaffold) {
  display: flex;
  align-items: center;
  transition: border var(--app-transition);
  cursor: pointer;
}

:local(.scaffold_isSelected) {
  border: var(--input-border-thickness, 1px) solid var(--highlight);
  transition: border var(--app-transition);
}

:local(.scaffold label) {
  padding-left: var(--space-small);
}

:local(.radio_label:after) {
  background: var(--highlight);
}

:global [type="radio"]:checked,
:global [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

:global [type="radio"]:checked + .rs,
:global [type="radio"]:not(:checked) + .rs {
  position: relative;
  width: 18px;
  height: 18px;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

:global [type="radio"]:checked + .rs:before,
:global [type="radio"]:not(:checked) + .rs:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

:global [type="radio"]:checked + .rs:after,
:global [type="radio"]:not(:checked) + .rs:after {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

:global [type="radio"]:not(:checked) + .rs:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

:global [type="radio"]:checked + .rs:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
