:local(.customise) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  width: 100%;
}

:local(.allTogether_question) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  width: 100%;
}

:local(.allTogether_question_trimIsDivider) {
  border-bottom: 1px solid var(--surface-trim);
}
