:local(.payWithCreditCard) {
}

:local(.payWithCreditCard_inputs) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.payWithCreditCard_inputs_scaffold) {
  composes: space-col from global;
  flex-grow: 1;
  justify-content: center;
}
