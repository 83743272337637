:local(.whitePanel) {
    border-radius: var(--surface-corners);
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: min(8rem, 20vw) 0 4rem 0;
    text-align: center;
    width: 100%;
    padding: 5.5rem 2rem 2rem 2rem;
}

:local(.sectionTitle) {
    margin-top: min(2rem, 4vw);
    font-size: 1.2rem;
}

:local(.benefitsContainer) {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    text-align: left;
    width: 100%;
}

:local(.benefitsContainer) > * {
    display: flex;
    align-items: center;
    gap: 1rem;
}

:local(.leftAligned) {
    text-align: left;
    align-self: flex-start;
}

:local(.whitePanel).stackedPrice {
    padding-top: min(35vw, 10rem);
    margin-top: min(8rem, 29vw);
}

@media screen and (max-width: 650px) {
    :local(.whitePanel) {
        padding: 15vw 1rem 1rem 1rem;
    }

    :local(.smallprint) {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 600px) {
    :local(.benefitsContainer) {
        grid-template-columns: 1fr;
    }
}
