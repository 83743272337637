:local(.datepicker_wrapper) {
  position: relative;
  width: 100%;
}

:local(.datepicker_input) {
  padding-left: 35px;
  width: 100%;
}

:local(.datepicker_icon) {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 11px;
  transform: translate(0, -50%);
}
