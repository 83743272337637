[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + .rs,
[type="radio"]:not(:checked) + .rs {
  position: relative;
  width: 18px;
  height: 18px;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="radio"]:checked + .rs:before,
[type="radio"]:not(:checked) + .rs:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + .rs:after,
[type="radio"]:not(:checked) + .rs:after {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + .rs:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + .rs:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
