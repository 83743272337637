:local(.scaffold) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
}

:local(.carDetails) {
  composes: base from global;
  composes: inside from global;
  align-items: flex-end;
}

:local(.carDetails_isSpacious) {
  composes: space-row from global;
}

:local(.carDetails_isSpaceLimited) {
  composes: space-col from global;
}

:local(.registration_input) {
  text-transform: uppercase;
}

:local(.registration_input::placeholder) {
  text-transform: none;
}

.grecaptcha-badge {
  visibility: hidden;
}