:local(.scaffolding) {
  composes: space-col from global;
  width: 100%;
}

:local(.vriSummary) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.vriSummary_description) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
  padding-bottom: var(--space-default);
}

:local(.vriSummary_callToAction) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  border-top: 1px solid var(--surface-trim);
  border-bottom: 1px solid var(--surface-trim);
}

:local(.vriSummary_smallPrint) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}
