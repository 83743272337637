:local(.action) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  cursor: pointer;
}

:local(.action_labels) {
  composes: space-col from global;
  flex: 1;
}
