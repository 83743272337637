:local(.productDashboardCustom) {
  padding: 25px;
  display: flex;
  justify-content: center;
  height: 100%;
}

:local(.productDashboardCustomError) {
  width: 100%;
  max-width: 728px;
  padding: 100px 0;
  text-align: center;
  font-size: 21px;
}
