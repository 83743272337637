:local(.appLoaderMask) {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

:local(.appLoader) {
  max-width: 180px;
  margin-bottom: 40px;
}

:local(.appLoaderOff) {
  opacity: 0;
  transition: all 3s ease-in;
}

:local(.appLoaderRemove) {
  display: none;
}

:local(.appLoaderText) {
  font-weight: 800;
  font-size: 18px;
  color: var(--primary-on);
}

:local(.appLoaderLine) {
  animation: expand 1s ease-in-out infinite;
  display: inline-block;
  transform-origin: center center;
  margin: 0 7px;
  width: 7px;
  border-radius: 7px;
  height: 7px;
}

:local(.appLoaderLine:nth-child(1)) {
  background: var(--primary);
}

:local(.appLoaderLine:nth-child(2)) {
  animation-delay: 180ms;
  background: var(--primary);
}

:local(.appLoaderLine:nth-child(3)) {
  animation-delay: 360ms;
  background: var(--primary);
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
}
