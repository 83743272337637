:local(.link) {
  cursor: pointer;
  text-decoration:  var(--text-decoration, underline);
  text-underline-position: under;
  color: var(--trigger, orange);
}

:local(.link:hover) {
  color: var(--trigger-hover, red);
  transition: color var(--app-transition);
}

:local(.link_isSmall) {
  font-size: var(--font-size-small, 12px);
}
