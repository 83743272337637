:local(.monthly_payment_summary_disclaimer) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.monthly_payment_summary_disclaimer_text) {
  font-size: 14px;
}
