:local(.page) {
  position: relative;
  width: 100%;
  flex: 1;
}

:local(.page_small) {
  max-width: var(--app-view-small-width, 768px);
}

:local(.page_base) {
  max-width: var(--app-view-base-width, 992px);
}

:local(.page_loader) {
  composes: space-col from global;
  composes: center from global;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

:local(.page_content) {
  position: relative;
  width: 100%;
  height: 100%;
}

:local(.page_content_isLoadingOver) {
  composes: disabled from global;
}

:local(.page_content_loadOver) {
  composes: space-row from global;
  composes: center from global;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
