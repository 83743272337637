:local(._frame) {
  width: 100%;
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
}

:local(._frame label) {
  font-weight: 400;
}

:local(._frame_error) {
  color: var(--app-error);
}
