:local(.moneyInput) {
  composes: space-row from global;
  position: relative;
}

:local(.moneyInput_symbol) {
  position: absolute;
  width: calc(var(--input-height) / 1.5);
  top: 0;
  left: 0;
  bottom: 0;
  composes: space-row from global;
  composes: center from global;
  font-weight: 500;
}

:local(.moneyInput_input) {
  padding-left: calc(var(--input-height) / 1.5);
}
