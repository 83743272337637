:local(.receiptAdjust_itemisedList) {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--background);
}

:local(.receiptAdjust_itemisedList:last-child) {
  border-bottom: none;
}

:local(.receiptAdjust_scaffold) {
  composes: space-col from global;
  flex-grow: 1;
}
