:local(.collection_form_wrapper) {
  position: relative;
}

:local(.collection_form_wrapper:before) {
  position: absolute;
  content: '';
  left: 0;
  top: var(--space-default);
  width: 5px;
  height: calc(100% - var(--space-large));
  background-color: var(--secondary);
  opacity: 0.5;
}

:local(.collection_form) {
  position: relative;
  width: 100%;
}

:local(.collection_form_buttons) {
  display: flex;
  justify-content: space-between;
  max-width: 520px;
  gap: 20px;
}

:local(.collection_form_buttons_wide) {
  composes: space-col from global;
  max-width: 100%;
  justify-content: initial;
}

:local(.collection_form_button) {
  max-width: 250px;
  font-size: var(--font-size-body);
  width: 100%;
}

:local(.collection_form_button_wide) {
  max-width: 100%;
  margin-bottom: var(--space-default);
}

:local(.collection_form_button_wide:last-child) {
  margin-bottom: 0;
}
