:local(.select) {
  composes: space-row from global;
  composes: center-vertical from global;
  width: 100%;
  background: var(--input);
  border-radius: var(--input-corners);
}

:local(.select > *) {
  flex: 1;
}

:local(.select_wrap) {
  position: relative;
}

:local(.select_wrap:hover .select_input_icon) {
  color: var(--trigger-on);
}

:local(.select_input_icon) {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 15px;
}

:local(.select_stepperAction) {
  width: 50px;
  max-width: 50px;
  height: var(--input-height, 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

:local(.select_stepperAction:first-child) {
  border-right: 1px solid var(--surface);
}

:local(.select_stepperAction:last-child) {
  border-left: 1px solid var(--surface);
}

:local(.select_stepperAction_increment) {
  color: var(--trigger);
}

:local(.select_stepperAction_decrement) {
  color: var(--trigger);
}

:local(.imagebox){
  max-width: 100%;
  padding: 10px;
  border: 1px solid;
}


:global .blade-input-superselect {
  width: 100%;
  position: relative;
  padding: 0;
}

:global .blade-input-superselect .r-ss-trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: var(--input-height, 40px);
  padding: 0 10px;
  border: 0;
  border-radius: var(--input-corners);
}

:global .blade-input-superselect .r-ss-trigger:hover {
  background: var(--trigger-hover);
  color: var(--trigger-on);
  transition: background var(--app-transition), color var(--app-transition);
}

:global .blade-input-superselect .r-ss-trigger:focus {
  background: var(--trigger-hover);
  color: var(--trigger-on);
  transition: background var(--app-transition), color var(--app-transition);
  outline: none;
}

:global .blade-input-superselect .r-ss-trigger:active {
  background: var(--trigger-hover);
  color: var(--trigger-on);
  transition: background var(--app-transition), color var(--app-transition);
  outline: none;
}

:global .blade-input-superselect .r-ss-dropdown .r-ss-search-wrap {
  z-index: 9;
  position: relative;
  height: var(--input-height, 40px);
  background: var(--select-background, --surface);
  border-top: 1px solid var(--surface-trim);
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-search-wrap
  .r-ss-search-inner {
  height: var(--input-height, 40px);
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-search-wrap
  .r-ss-search-inner
  .r-ss-search-aria-label {
  display: none;
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-search-wrap
  .r-ss-search-inner
  .r-ss-magnifier {
  display: none;
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-search-wrap
  .r-ss-search-inner
  .r-ss-search-clear {
  display: none;
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-search-wrap
  .r-ss-search-inner
  input {
  width: 100%;
  border: 0;
  height: var(--input-height, 40px);
  border-radius: 0;
  padding: 0 var(--space-default);
  text-decoration: none;
  background: var(--surface-aside);
  color: var(--surface-on);
  font-size: 14px;
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-search-wrap
  .r-ss-search-inner
  input:focus {
  outline: none;
}

:global .blade-input-superselect .r-ss-dropdown .r-ss-options-wrap {
  z-index: 9;
  cursor: pointer;
  position: relative;
  height: auto !important;
  max-height: 300px;
  overflow-y: scroll;
  background: var(--surface);
  box-shadow: 0px 1px 10px 1px var(--surface-trim);
  border-right: 1px solid var(--surface-trim);
  border-bottom: 1px solid var(--surface-trim);
  border-left: 1px solid var(--surface-trim);
  border-bottom-right-radius: var(--surface-corners);
  border-bottom-left-radius: var(--surface-corners);
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-options-wrap
  .r-ss-dropdown-option {
  display: flex;
  align-items: center;
  min-height: 50px;
  word-wrap: break-word;
  user-select: none;
  background: var(--select-background, --surface);
  transition: background var(--app-transition);
  padding: var(--space-default);
  border-bottom: var(--select-item-border-bottom ,1px solid var(--background));
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-options-wrap
  .r-ss-dropdown-option:first-child {
  border-top: 1px solid var(--background);
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-options-wrap
  .r-ss-dropdown-option:hover {
  background: var(--trigger-hover);
  color: var(--trigger-on);
  transition: background var(--app-transition), color var(--app-transition);
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-options-wrap
  .r-ss-dropdown-option:focus {
  outline: none;
  border: none;
}

:global
  .blade-input-superselect
  .r-ss-dropdown
  .r-ss-options-wrap
  .r-ss-dropdown-option.r-ss-selected {
  background: var(--highlight);
  color: var(--highlight-on);
  transition: background var(--app-transition), color var(--app-transition);
}

:global
.r-ss-tag {
  display: inline-block;
  position: relative;
  padding: 0 20px 0 7px;
  margin: 0 0 .25rem .95rem;
  background-color: var(--display);
  color: var(--display-on);
  max-width: 95%;
  min-height: 24px;
  text-overflow: ellipsis;
  line-height: 22px;
  border-radius:(4px, 4px, 4px, 4px);
  box-shadow: 1px 1px 1px rgb(191, 196, 176);
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

:global
.r-ss-tag ::before {
  content: '';
  position: absolute;
  left: -23px;
  top: 0;
  height: 0;
  width: 0;
  border-top: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid var(--display);
}

:global
.r-ss-tag ::after {
  content: '';
  position: absolute;
  left: -.2rem;
  top: 9px;
  height: 5px;
  width: 5px;
  background-color: var(--display);
  border: 1px solid #999;
  border-radius:(5px, 5px, 5px, 5px);
}

:global
.r-ss-remove-button {
  opacity: .25;
  position: absolute;
  width: 14px;
  height: 14px;
  padding: 0;
  color: #333;
  background-color: var(--display);
  font-size: 10px;
  border: 1px solid var(--display);
  cursor: pointer;
  z-index: 1;
  border-radius:(2px, 2px, 2px, 2px);
}

:global
.r-ss-selection-clear {
  position: absolute;
  left: 5px;
  top: 50px;
  background: var(--background);
}

:global
.r-ss-selection-clear::before {
  content: 'Clear All';
  color:var(--input-on)
}

:global
.r-ss-selection-clear:hover {
cursor: pointer;
}

:global
.r-ss-tag-remove {
  background-color: var(--display);
}
