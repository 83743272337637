:local(.carDetails) {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
  width: 100%;
}

:local(.carDetails_content) {
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
  border: 1px solid var(--surface-trim);
  border-radius: var(--surface-corners);
}

:local(.carDetails_content_isSpacious) {
  composes: space-row from global;
  align-items: center;
}

:local(.carDetails_content_isSpaceLimited) {
  composes: space-col from global;
  align-items: center;
}

:local(.carDetails_content_isSpaceLimited .carDetails_content_breakdown) {
  order: 1;
  align-items: center;
  text-align: center;
}

:local(.carDetails_content_isSpaceLimited .carDetails_content_rego) {
  order: 0;
}

:local(.carDetails_content_breakdown) {
  min-width: 200px;
  flex: 1;
  composes: space-col from global;
  composes: small from global;
  composes: inside from global;
}

:local(.carDetails_content_breakdown > *) {
  margin-bottom: 0 !important;
}

:local(.carDetails_content_breakdown .specification_details) {
  font-size: 14px;
}

:local(.carDetails_content_breakdown .specification_details span) {
  padding-right: 0.25em;
  display: inline-block;
  white-space: nowrap;
}

:local(.carDetails_content_rego) {
  width: 30%;
  min-width: 200px;
  color: var(--highlight);
  border: 2px solid var(--highlight);
  border-radius: var(--surface-corners);
  composes: space-col from global;
  composes: base from global;
  composes: frame from global;
  composes: center from global;
}

:local(.carDetails_content_rego p:first-child) {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 3px;
}

:local(.carDetails_content_rego p:last-child) {
  font-size: 14px;
  font-weight: bold;
  justify-content: flex-end;
}

:local(.carDetails_title) {
  font-size: 18px;
}
