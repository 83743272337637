:local(.receiptSummary) {
  composes: space-col from global;
  flex-grow: 1;
  padding: 20px 0;
  border-bottom: 1px solid var(--surface-trim);
  align-items: center;
}

:local(.receiptSummary_label) {
  text-align: center;
  font-size: 22px;
}

:local(.receiptSummary_coveredRange) {
  composes: space-row from global;
  composes: small from global;
  composes: frame from global;
  composes: inside from global;
  align-items: baseline;
}

:local(.receiptSummary_coveredRange_pill) {
  font-size: 13px;
  border-radius: var(--surface-corners, 2px);
  background-color: var(--surface-aside);
  padding: 4px;
}

:local(.receiptSummary_reference) {
  font-size: 14px;
  opacity: 0.5;
}
