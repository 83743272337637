:local(.callToAction) {
  composes: space-row from global;
  composes: center-vertical from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
  flex-grow: 1;
  width: 100%;
  background-color: var(--surface, white);
  color: var(--surface-on, black);
  border-radius: var(--surface-corners, 3px);
  transition: background-color var(--app-transition), color var(--app-transition);
  cursor: pointer;
  box-shadow: var(--surface-depth)
}

:local(.callToAction:hover) {
  background-color: var(--trigger-hover, orange);
  color: var(--trigger-on, white);
  transform: var(--transform-hover);
  transition: background-color var(--app-transition), color var(--app-transition);
}

:local(.callToAction:hover path) {
  fill: var(--trigger-on, white);
}

:local(.callToAction_image) {
  width: 50px;
  flex-shrink: 0;
}

:local(.callToAction_titles) {
  composes: space-col from global;
  composes: small from global;
  composes: inside from global;
  flex-grow: 1;
}

:local(.callToAction:hover h4) {
  color: var(--trigger-on, white);
}

:local(.callToAction_icon) {
  font-size: 21px;
}

:local(.gold) {
  composes: h6 from global;
  color: orange;
}
