:local(.policyListMessage) {
  font-size: 21px;
  font-weight: 300;
  padding-top: 60px;
  padding-bottom: 15px;
}
:local(.policyListMessage_empty_list) {
  display: flex;
  justify-content: center;
}
:local(.policyListMessage_welcome) {
  opacity: 0.8;
  padding-right: 5px;
  font-weight: 700;
}
:local(.policyListMessage_mobile) {
  padding-top: 0px !important;
}
:local(.policyListMessage_mobile_empty) {
  font-size: 17px;
}

:local(.policyListMessage_empty) {
  font-weight: 500;
  padding-left: 15px;
}

:local(.policyListMessage_image) {
  max-width: 24px;
  max-height: 22px;
}
