:local(.text_scaffold) {
  position: relative;
  width: 100%;
}

:local(.text_scaffold input::placeholder) {
  opacity: 0.5;
}

:local(.text_action) {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: var(--input-height, 40px);
  color: var(--trigger);
  cursor: pointer;
  composes: space-row from global;
  composes: base from global;
  composes: inside from global;
  composes: center from global;
}

:local(.text_action_loader) {
  left: -20px;
}

:local(.imagebox) {
  max-width: 100%;
  padding: 10px;
  border: 1px solid;
}
