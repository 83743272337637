:global(#checkoutIframe) {
  width: 100%;
}

:global(#checkoutIframe iframe) {
  display: block;
  position: relative;
  border: none;
  outline: none;
  min-width: 300px;
  min-height: 335px;
  width: 100%;
  background: transparent;
  overflow: hidden;
}

:local(.checkout_wrapper) {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 335px;
}
