:local(.progressBarContainer) {
    margin: 1.5rem 0 3rem 0;
    display: inline-flex;
    justify-content: center;
    gap: 1.5rem;
    background: linear-gradient(transparent calc(50% - 1px), var(--primary) calc(50%), transparent calc(50% + 1px));
    color: var(--primary);
    align-items: center;
    gap: min(1.5rem, 4vw);
}

:local(.progressBarContainer) :local(.currentStep) {
    display: flex;
    font-weight: 600;
    align-items: center;
    gap: 0.6rem;
    border: 2px solid var(--primary);
    border-radius: 2rem;
    padding-right: 1rem;
    overflow: hidden;
    background: linear-gradient(to right, var(--primary) 1rem, #fff 1rem);
    box-shadow: 1px 1px var(--background-trim);
    flex-shrink: 0;
}

:local(.progressBarContainer) :local(.currentStep) :first-child {
    border-radius: 2rem;
    background: var(--primary);
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding-right: 2px;
}

:local(.progressBarContainer) :local(.progressStep) {
    border: 1px solid var(--primary);
    border-radius: 100%;
    background: #fff;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    position: relative;
    flex-shrink: 0;
}

:local(.progressBarContainer) :local(.progressStep):last-child::after {
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    top: -18px;
    left: 9px;
    background-color: var(--primary);
    mask-image: url("https://src.aventus.app/assets/stella/images/icon/motor/checkered-flag.svg");
}

:local(.centred) {
    text-align: center;
    align-self: center;
}
