:local(.dp_header) {
  composes: space-col from global;
}

:local(.dp_header_title) {
  color: var(--input-on);
  font-size: 12px;
  opacity: 0.7;
}

:local(.dp_header_button) {
  padding: 15px 0;
  border-bottom: 1px solid var(--input);
  cursor: pointer;
  color: var(--input-on);
  font-size: 11px;
  text-transform: uppercase;
  opacity: 0.7;
}
:local(.dp_header_controls) {
  composes: space-row from global;
  composes: space-between from global;
  padding: 10px 15px;
}

:local(.dp_header_chevron) {
  color: var(--input-on);
  opacity: 0.5;
}

:local(.dp_header_icons) {
  composes: space-row from global;
  composes: space-between from global;
  width: 50px;
}

:local(.dp_header_chevron:hover) {
  color: var(--primary-on);
  opacity: 0.3;
}
:local(.dp_header_chevron_disabled) {
  opacity: 0.5;
}
