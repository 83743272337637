:local .customise {
  composes: space-row from global;
  width: 100%;
  flex-wrap: wrap;
}

:local .allTogether_question_isPanel_3Col {
  width: 29%;
  min-width: 200px;
  flex-grow: 1;
  flex-shrink: 0;
  composes: space-col from global;
  margin-bottom: var(--space-large);
}

:local .alltogether_question_isDefault {
  width: 100%;
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  margin-bottom: calc(var(--space-large));
}

:local .allTogether_question_panel_3Col {
  height: 100%;
  min-height: 350px;
  border: 1px solid var(--input);
  margin: 0 !important;
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  border-radius: var(--surface-corners);
  border: 1px solid var(--background);
}

/* outer wrapper: */
:local .allTogether_question_isPanel_2Col {
  width: 50%;
  min-width: 350px;
  flex-grow: 1;
  flex-shrink: 0;
  composes: space-col from global;
  background-color: transparent;
}

@media screen and (max-width: 400px) {
  :local .allTogether_question_isPanel_2Col {
    min-width: auto;
    width: 100%;
  }
}

/* inner wrapper:  */
:local .allTogether_question_panel_2Col {
  height: 100%;
  border: 1px solid var(--input);
  composes: space-row from global;
  composes: base from global;
  composes: frame from global;
  border-radius: var(--input-corners);
  border: 1px solid var(--background);
}

:local(.allTogether_question_panel_2Col).space-row.base.frame{
  margin: 0;
  padding: 15px 0;
}

@media screen and (min-width: 750px) {
  .blade-surface:nth-child(odd of .space-col) div :local(.allTogether_question_panel_2Col).space-row.base.frame{
    padding-right: 15px; 
  }
  
  .blade-surface:nth-child(even of .space-col) div :local(.allTogether_question_panel_2Col).space-row.base.frame{
    padding-left: 15px; 
  }

  .blade-surface:nth-last-child(-n + 1 of .space-col) div :local(.allTogether_question_panel_2Col).space-row.base.frame{
    padding-right: 0; 
    padding-left: 0; 
  }

}

:local .allTogether_question_isSectionHeader {
  margin-bottom: var(--space-default);
  margin-top: var(--space-large);
}
