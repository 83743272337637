:local(.actionList) {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
}

:local(.actionListItem) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  cursor: pointer;
  background-color: var(--surface, white);
  color: var(--surface-on, black);
  border-radius: var(--surface-corners, 3px);
  transition: background-color var(--app-transition),
    color var(--app-transition);
  justify-content: space-between;
  align-items: center;
}

:local(.actionListItem_alt) {
  background-color: var(--surface-aside, grey);
}

:local(.actionListItem:hover) {
  background-color: var(--trigger-hover, orange);
  color: var(--trigger-on, white);
  transition: background-color var(--app-transition),
    color var(--app-transition);
}

:local(.actionListItem_disabled) {
  cursor: default;
}

:local(.actionListItem_disabled:hover) {
  background-color: var(--surface, white);
  color: var(--surface-on, black);
}

:local(.actionListItem_content) {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

:local(.actionListItem_contentInner) {
  composes: space-row from global;
  composes: base from global;
  composes: inside from global;
  align-items: center;
}

:local(.actionListItem_contentImage) {
  width: 20px;
  height: 20px;
}

:local(.actionListItem_content_disabled) {
  opacity: 0.5;
}

:local(.actionListItemAlert) {
  width: 100%;
  margin-top: 15px;
}
