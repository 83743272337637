:local(.text_scaffold) {
  position: relative;
  width: 100%;
}

:local(.text_action) {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: var(--input-height, 40px);
  color: var(--trigger);
  cursor: pointer;
  composes: space-row from global;
  composes: base from global;
  composes: inside from global;
  composes: center from global;
}

:local(.text_action_loader) {
  left: -40px;
}
