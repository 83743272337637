:local(.estimateOverlay) {
  width: 400px!important;
}

:local(.estimateOverlay_banner) {
  height: 100px;
}

:local(.estimateOverlay_disclaimer > div) {
  padding: 25px;
  text-align: center;
}

:local(.estimateOverlay_action) {
  padding: 25px;
}
