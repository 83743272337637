:local .formFieldset {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
}

:local(.formFieldset_scaffold) {
  width: 100%;
  height: 100%;
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
  justify-content: space-between !important;
}

/* :local .formFieldset_scaffold_details {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
} */

:local .formFieldset_scaffold_inputs {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
}

:local .formFieldset_assetBar_image {
  width: 100%;
  max-width: 50px;
  max-height: 42px;
}

:local .formFieldset_assetBar_icon {
  font-size: 30px;
}

:local .formFieldset_topLefTitle {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

:local .formFieldset_questionBar {
  composes: space-row from global;
  justify-content: space-between;
  position: relative;
}

:local .formFieldset_questionBarTooltip {
  position: absolute;
  right: 0;
  top: 0;
}

:local .formFieldset_questionBar_legend {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

:local .formFieldset_spacetop {
  padding-top: 50px;
}

:local .formFieldset_errorMessage {
  font-size: 14px;
  color: var(--app-error);
}

:local .formFieldset_addonBottom {
  margin-top: 15px;
  font-size: 14px;
}

:local .formFieldset_help {
  font-weight: 400;
  font-size: 14px;
  color: var(--surface-aside-on);
}

:local .formFieldset_moreDetails_link {
  padding: var(--space-default) 0;
}

:local .formFieldset_inputs_isGroup {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
}

:local(.formFieldset_icon_right_wrapper) {
  position: absolute !important;
  top: 0;
  right: 0;
  background: var(--highlight);
  border-radius: 50%;
  box-sizing: content-box;
  margin-bottom: 0 !important;
  padding: 3px;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
}

:local(.formFieldset_icon_right) {
  margin-bottom: 0 !important;
}

:local(.fieldSetHasError) legend {
  color: var(--app-error, red);
}

:local(.fieldSetHasError .formFieldset_scaffold_inputs) input,
:local(.fieldSetHasError .formFieldset_scaffold_inputs) select,
:local(.fieldSetHasError .formFieldset_scaffold_inputs) textarea,
:local(.fieldSetHasError .formFieldset_scaffold_inputs) button,
:local(.fieldSetHasError .formFieldset_scaffold_inputs) .r-ss-wrap {
  border: 1px solid var(--app-error, red);
}

:local(.fieldSetRequired) {
  display: inline-block;
  color: var(--app-error, red);
  margin-left: 3px;
}
