:local(.icon) {
    background-repeat: no-repeat;
    background-position: center center;
    flex-shrink: 0;
}

:local(.benefitIcon) {
    composes: icon;

    width: 3.5rem;
    height: 3.5rem;
    background-color: var(--background);
    border-radius: 100%;
    background-size: 2.6rem;
    box-shadow: 2px 2px 10px #eee6da;
}

:local(.accidentIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/damaged-car.svg");
}

:local(.newCarIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/car-replacement.svg");
    background-size: 85%;
}

:local(.noClaimIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/rosette.svg");
    background-size: 80%;
    background-position: calc(50% - 1px) center;
}

:local(.hireCarIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/accident.svg");
    background-size: 85%;
    background-position: calc(50% + 1px) center;
}

:local(.thirdPartyIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/30-million.svg");
    background-size: 85%;
    background-position: center calc(50% - 2px);
}

:local(.personalItemsIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/handbag.svg");
    background-size: 70%;
    background-position: calc(50% + 1px) calc(50% - 3px);
}

:local(.repairsIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/spanner.svg");
}

:local(.babyGearIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/pram.svg");
}

:local(.spannerIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/spanner.svg");
}

:local(.windscreenIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/windscreen.svg");
}

:local(.keysIcon) {
    composes: benefitIcon;
    background-image: url("https://src.aventus.app/assets/stella/images/icon/motor/yellow/car-keys.svg");
}

:local(.lockIcon) {
    composes: icon;
    width: 1.3rem;
    height: 1.3rem;
    background-color: #c6b3e3;
    mask-image: url("https://src.aventus.app/assets/stella/images/icon/motor/padlock.svg");
    mask-repeat: no-repeat;
}
