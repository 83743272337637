:local(.scaffold) {
  width: 100%;
  composes: space-col from global;
}

:local(.summary) {
  composes: space-col from global;
  composes: frame from global;
  composes: large from global;
  composes: inside from global;
}

:local(.summary_documents_notification) {
  max-width: 525px;
  width: 100%;
  top: 45px;
  margin: 0 !important;
  right: 45px;
}

:local(.summary_documents_notification_mobile) {
  right: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
}
