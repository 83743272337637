:local(.scaffolding) {
  composes: space-col from global;
  width: 100%;
}

:local(.askWrapper) {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  flex-shrink: 0;
}

:local(.ask) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.ask_controls) {
  composes: space-row from global;
  composes: large from global;
  composes: inside from global;
  width: 100%;
}

:local(.ask_controls_nextWrap) {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
  width: 100%;
  align-items: center;
}

:local(.ask_controls_isSpaceLimited) {
  composes: space-col-reverse from global;
}

:local(.ask_control_padding) {
  padding: 0 12px;
  white-space: nowrap;
}

:local(.ask_controls_isSpaceLimited > *:last-child) {
  margin-bottom: 25px;
}

:local(.scaffoldingModal) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
}

:local(.buttonWrapper) {
  composes: space-row from global;
  composes: large from global;
  composes: inside from global;
  width: 100%;
}

:local(.messageWrapper) {
  margin: 20px 0;
}

:local(.simpleQuoteLoading) {
  align-self: center;
}
