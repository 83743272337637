:local .switchWrap {
  display: flex;
  align-items: center;
}

:local .switchWrap label {
  padding-left: 10px;
}

:global .switch {
  width: 48px;
  min-width: 48px;
  height: 24px;
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  background: var(--input-aside);
  transition: width 2s;
}


:local(.switch-enter-active) {
  opacity: 1;
  transition: opacity 700ms ease-in;
}
:local(.switch-exit-active) {
  opacity: 0;
  transition: opacity 700ms ease-out;
}


:global .switch.on {
  background: var(--highlight);
}

:global .switch.on .switch-toggle {
  left: 24px;
  border: 2px solid var(--highlight);
}

:global .switch .switch-toggle {
  border: 2px solid var(--input-aside);
  width: 24px;
  height: 24px;
  left: 0;
  border-radius: 50%;
  background: var(--surface);
  position: relative;
  transition: left .2s ease-in-out;
}

:local(.loading){
  opacity: 1;
  padding-top:10px;
}

:local(.loading-enter-active) {
  opacity: 1;
  transition: opacity 700ms ease-in;
}

:local(.loading-exit-active) {
  opacity: 0;
  transition: opacity 700ms ease-out;
}
