:local(.progressSurface) {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--surface);
  padding: 30px var(--space-large);
  width: 100%;
}

:local(.progressBarWrapper) {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: var(--space-default, 14px ) 0;
  transition-delay: 200ms;
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

:local(.progressBarWrapper).progress-bar-appear {
  opacity: 0;
}

:local(.progressBarWrapper).progress-bar-appear-active {
  opacity: 1;
}

:local(.progressBarWrapper *) {
  box-sizing: border-box;
}

:local(.progressBar) {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  border-top: 3px solid var(--primary, #ccc);
  padding: 0;
  margin: 0;
  list-style: none;
}

:local(.progressLabel) {
  position: relative;
  padding: var(--space-default, 14px ) 0;
  font-size: 12px;
  font-weight: var(--trigger-font-weight, bold);
  color: var(--surface-on);
}

:local(.progressStep) {
  position: relative;
  width: 20px;
  height: 20px;
  padding: 9px 0 0 0;
  font-weight: var(--trigger-font-weight, bold);
  color: var(--surface-on);
}

:local(.progressStep)::before {
  position: absolute;
  content: "";
  top: -11px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 3px solid var(--primary, #ccc);
  background: var(--surface);
  border-radius: 20px;
  box-sizing: border-box;
  transition-delay: 200ms;
  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

:local(.progressStep)::after {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background: var(--surface-on, #000);
  border-radius: 20px;
  box-sizing: border-box;
  z-index: 9;
  opacity: 0;
  transition-delay: 50ms;
  transition-duration: 200ms, 300ms;
  transition-property: top, opacity;
  transition-timing-function: ease-in-out;
}

:local(.progressStepActive)::after {
  top: -4px;
  opacity: 1;
}

:local(.progressStepActive) {
  position: relative;
}

:local(.progressStepComplete) {
  position: relative;
}

:local(.progressStepComplete)::before {
  background: var(--primary, #ccc);
}

:local(.progressStepName) {
  position: relative;
  display: none;
}

@media (min-width: 768px) {
  :local(.progressSurface) {
    padding: 60px var(--space-large);
  }

  :local(.progressBarWrapper) {
    padding: var(--space-default) 65px;
  }

  :local(.progressBar) {
    padding: 0;
    border: 0;
    border-top: 3px solid var(--primary, #ccc);
  }

  :local(.progressStep) {
    width: 34px;
    height: 34px;
    margin: 0;
    padding: 30px var(--space-default) var(--space-default);
    text-align: center;
  }

  :local(.progressLabel) {
    display: none;
  }

  :local(.progressStep)::before {
    width: 34px;
    height: 34px;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }

  :local(.progressStepActive)::after {
    width: 10px;
    height: 10px;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
  }

  :local(.progressStepName) {
    position: relative;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    width: 130px;
  }
}
