:local(.view) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
  width: 100%;
}

:local(.updateSavedCard) {
  composes: space-col from global;
}

:local(.updateSavedCard_details) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
  composes: center from global;
}

:local(.updateSavedCard_details_label) {
  font-size: 18px;
  font-weight: 700;
}

:local(.updateSavedCard_element) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: center from global;
}

:local(.updateSavedCard_controls) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}
