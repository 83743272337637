:local(.bnpSurface) .blade-message {
  margin-bottom: var(--space-large, 25px);
}

:local(.bnpViewsWrapper) {
  display: block;
  position: relative;
}

:local(.bnpButtonWrap) {
  display: flex;
  justify-content: space-around;
}

:local(.bnpButtonWrap) > button {
  max-width: 49% !important;
  animation: none;
  transition: none;
}

:local(.bnpButtonWrap) button:only-child {
  max-width: 100% !important;
}

:local(.bnpFieldWrapper) {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--space-large) 0;
}

:local(.bnpFieldset) {
  width: 100%;
  padding-bottom: 10px;
}

:local(.bnpFieldError) {
  display: inline-block;
  color: var(--app-error);
  padding-top: 15px;
}

:local(.bnpControllerHalf) {
  max-width: 49%;
}

:local(.bnpControllerFull) {
  max-width: 100%;
}

:local(.bnpTermsWrapper) {
  width: 100%;
  padding: var(--space-large) var(--space-default);
}

:local(.bnpTermsWrapper) p {
  padding-bottom: var(--space-small);
}

:local(.bnpFinanceGuarenteeWrapper) {
  width: 100%;
  padding: var(--space-default) var(--space-default) var(--space-large);
}

:local(.bnpFinanceGuarenteeWrapper) ul {
  padding-left: var(--space-default)
}
