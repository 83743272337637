:local(.collection) {
  display: initial;
}

:local(.additionalText) {
  font-weight: 400;
  font-size: 14px;
  color: var(--surface-aside-on);
  margin-top: -30px;
}

:local(.collection_content) {
  padding-bottom: var(--space-default);
}

:local(._frame_error) {
  color: var(--app-error);
}
