:local(.list) {
  composes: space-col from global;
  width: 100%;
}

:local(.list_item_isClickable) {
  cursor: pointer;
}

:local(.list_title) {
  composes: space-row from global;
  composes: center-vertical from global;
  padding-top: var(--space-default, 15px);
  padding-bottom: var(--space-default, 15px);
  border-bottom: 3px solid var(--surface-trim);
}

:local(.list_isSmall .list_title) {
  padding-top: var(--space-small, 10px);
  padding-bottom: var(--space-small, 10px);
}

:local(.list_item) {
  composes: space-col from global;
  border-bottom: 1px solid var(--surface-trim);
}

:local(.list_item_border) {
  margin-top: -1px;
  border-top: 1px solid var(--surface-on);
  border-bottom: 1px solid var(--surface-on)
}

:local(.list_item:last-child) {
  border-bottom: 0;
}

:local(.list_item_row) {
  composes: space-row from global;
  composes: center-vertical from global;
  justify-content: space-between;
  padding: var(--space-default) 0px;
}

:local(.list_item_row_mobile) {
  display: inline-grid !important;
}

:local(.list_isSmall .list_item_row) {
  padding: var(--space-small, 10px) 0;
}

:local(.list_isFlush .list_item_row) {
  padding: var(--space-default) var(--space-large, 15px);
}

:local(.list_isSmall.list_isFlush .list_item_row) {
  padding: var(--space-small, 10px) var(--space-default, 15px);
}

:local(.list_item_row_key) {
  composes: space-row from global;
  composes: base from global;
  composes: inside from global;
  align-items: center;
}

:local(.list_item_row_array) {
  composes: space-row from global;
  composes: center-vertical from global;
  justify-content: space-between;
  align-items: baseline;
}

:local(.list_isFlush .list_item_row_array) {
  padding: var(--space-default) var(--space-large, 15px);
  justify-content: space-between;
  align-items: baseline;
}

:local(.list_item_row_listItem:first-child) {
  padding-top: 0;
}

:local(.list_item_row_key_array) {
  composes: space-row from global;
  composes: inside from global;
  padding: var(--space-default) 0px;
  align-items: baseline;
}

:local(.list_item_row_key_mobile) {
  display: inline-grid !important;
  padding-bottom: 5px;
}

:local(.list_item_row_value) {
  font-weight: 500;
}

:local(.list_item_moreDetails) {
  composes: space-row from global;
  composes: center-horizontal from global;
  composes: base from global;
  composes: frame from global;
  padding-top: var(--space-default, 15px);
  border-top: 1px solid var(--surface-trim);
  background-color: var(--background-aside, #f7f7f7);
}

:local(.list_isSmall .list_item_moreDetails) {
  padding-top: var(--space-small, 10px);
}

:local(.list_item_label) {
  font-weight: 400;
}

:local(.list_item_label_bold) {
  font-weight: 700;
}

:local(.list_item_row_list li) {
  padding: 5px;
  text-align: right;
  font-size: var(--font-size-body, 18px);
}

:local(.list_item_row_list_boldItem) {
  font-weight: 700;
}

:local(.list_item_row_list_compact li) {
  padding: 0 5px;
}

:local(.list_item_row_list_mobile li) {
  padding: 0 5px 0 0;
  text-align: left;
}

:local(.list_item_toggle) {
  background-color: transparent;
  border: 0px;
  margin-top: 0;
}

:local(.list_item_additionalBlurb) {
  width: 100%;
  margin-left: 25px;
  padding-bottom: 15px;
}

:local(.toggleOverride) {
  border: none;
}

:local(.list_item_button_action) {
  padding: 0;
  font-size: 14px;
}


:local(.list_item_button_close) {
  margin-left: 6px;
  width: 60px !important;
}

:local(.list_item_action_panel) {
  display: flex;
}