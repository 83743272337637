/* Claim Display */

:local(.claims_element) {
  border: 1px solid var(--input-border-color-special);
  box-sizing: border-box;
  border-radius: 3px;
  padding: var(--space-default) var(--space-default);
  margin-bottom: var(--space-default);
}

:local(.claims_element_footer) {
  padding-top: var(--space-default);
}

:local(.claims_element_footer_link) {
  padding-right: var(--space-default);
  font-size: 14px;
}
:local(.claims_element_footer_link:last-child) {
  padding-right: 0;
}

:local(.claims_list li) {
  padding-bottom: var(--space-default);
}

:local(.claims_list li b) {
  padding-right: var(--space-small);
}

:local(.claims_table tr td) {
  padding: 5px 0;
  width: auto;
}

:local(.claims_table tr td:last-child) {
  padding-left: var(--space-large);
  max-width: 370px;
}

/* Claim Form */

:local(.claims_form) {
  position: relative;
  padding: var(--space-large) 0 var(--space-large) var(--space-default);
}

:local(.claims_form_buttons) {
  display: flex;
  justify-content: space-between;
  max-width: 435px;
}

:local(.claims_form_buttons_wide) {
  composes: space-col from global;
  max-width: 100%;
  justify-content: initial;
}

:local(.claims_form_button) {
  max-width: 212px;
  font-size: var(--font-size-body);
  width: 100%;
}

:local(.claims_form_button_wide) {
  max-width: 100%;
  margin-bottom: var(--space-default);
}

:local(.claims_form_button_wide:last-child) {
  margin-bottom: 0;
}

:local(.claims_form:before) {
  position: absolute;
  content: '';
  left: 0;
  top: var(--space-default);
  width: 5px;
  height: calc(100% - var(--space-large));
  background-color: var(--secondary);
  opacity: 0.5;
}
