:local(.display) {
  min-height: var(--title-bar-height, 100px);
}

:local(.view) {
  display: flex;
  align-items: center;
}

:local(.titleBar) {
  composes: space-col from global;
  background-color: var(--title-bar);
  align-items: center;
  text-align: center;
  position: relative;
}

:local(.tooltip) {
  position: absolute;
  right: 25px;
  top: 5px;
}

:local(.titleBar *) {
  color: var(--title-bar-on);
}

:local(.titleBar h2) {
  margin-bottom: 6px;
}

:local(.titleBar_description) {
  opacity: 0.8;
}
