:local(.input) {
  font-size: var(--font-size-body);
  font-family: var(--font-main);
  border: none;
  height: var(--input-height);
  padding: 0 10px;
  border-radius: var(--input-corners);
  cursor: text;
  color: var(--input-on);
}

:local(.input:focus) {
  outline: none;
  border-bottom: 2px solid var(--highlight);
  transition: color var(--app-transition), border-bottom var(--app-transition);
}

:local(.input_display_block) {
  width: 100%;
}

:local(.input_display_inline) {
  width: auto;
  align-self: start;
}

:local(.input_variant_solid) {
  background: var(--input);
}

:local(.input_variant_underline) {
  background: var(--surface);
  border-bottom: var(--input-border-thickness, 1px) solid var(--input);
}

:local(.input_variant_border) {
  background: var(--surface);
  border: var(--input-border-thickness, 1px) solid var(--input);
}

:local(.input_variant_noFrame) {
  padding: 0;
}
