:local(.receipt) {
  composes: space-col from global;
  width: 100%;
}

:local(.receipt_items) {
  composes: space-col from global;
  min-height: 50px;
  padding: 12px 0 0;
  justify-content: space-between;
  background-color: var(--surface, white);
}

:local(.receipt_items_content) {
  display: flex;
}

:local(.receipt_footnote) {
  composes: space-row from global;
  background-color: var(--surface, white);
}

:local(.receipt_footnote_text) {
  composes: space-row from global;
  composes: center from global;
  width: 100%;
  min-height: 50px;
  align-self: center;
  text-align: center;
  font-size: 12px;
  border-top: 2px solid var(--surface-trim, #EFEFEF);
  background-color: var(--surface-aside, #f7f7f7);
  padding: var(--space-small, 10px) var(--space-default, 15px);
}

:local(.receipt_footnote_text *) {
  color: var(--surface-aside-on);
}

:local(.receipt_zigzag) {
  width: 100%;
  height: 14px;
  background-color: transparent;
  background-size: 20px 20px;
  background-repeat: repeat-x;
}
