:local(.quoteWrapper) {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  flex-shrink: 0;
}

:local(.error_notification) {
  width: 300px;
  top: 45px;
  margin: 0!important;
  right: 45px;
}
