:local(.button) {
  width: 100%;
  height: var(--trigger-height, 60px);
  min-height: var(--trigger-height, 60px);
  background-color: var(--trigger, orange);
  border-radius: var(--button-corners, 3px);
  cursor: pointer;
  font-size: var(--trigger-font-size, 24px);
  font-weight: bold;
  color: var(--trigger-on, white);
  transition: background-color var(--app-transition), var(--app-transition);
}

:local(.button:hover) {
  background-color: var(--trigger-hover, red);
  transform: var(--transform-hover);
  transition: background-color var(--app-transition), var(--app-transition);
}

:local(.button:active) {
  background-color: var(--trigger-hover, red);
  transform: var(--transform-hover);
  transition: background-color var(--app-transition), var(--app-transition);
}

:local(.inline) {
  width: auto;
}

:local(.destructive) {
  background-color: var(--app-error, red);
  color: var(--app-error-on, white);
}

:local(.destructive:hover) {
  background-color: black;
}

:local(.disabled) {
  opacity: 0.2;
  pointer-events: none;
}

:local(.inverted) {
  background-color: var(--trigger-on, white);
  border: 3px solid var(--trigger, orange);
  color: var(--trigger, orange);
}

:local(.inverted:hover) {
  background-color: var(--trigger, white);
  border: 0;
  color: var(--trigger-on, white);
}

:local(.secondary) {
  background-color: var(--trigger-on, white);
  border: 1px solid var(--trigger, pink);
  color: var(--trigger, white);
}

:local(.secondary:hover) {
  background-color: var(--trigger, white);
  border: 1px solid var(--trigger, pink);
  color: var(--trigger-on, white);
}

:local(.secondary:focus) {
  background-color: var(--trigger);
  border: 1px solid var(--trigger, pink);
  color: var(--trigger-on, white);
}
