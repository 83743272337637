:local(.documents) {
  composes: space-col from global;
  composes: frame from global;
  composes: large from global;
  composes: inside from global;
}

:local(.loading) {
  margin: 0 auto;
  padding: 20px 0;
}
