:local(.scaffolding) {
  composes: space-col from global;
  width: 100%;
}

:local(.askWrapper) {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  flex-shrink: 0;
}

:local(.ask) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.ask_controls_isSpaceLimited) {
  composes: space-col-reverse from global;
}

:local(.ask_control_padding) {
  padding: 0 12px;
  white-space: nowrap;
}

:local(.ask_controls_isSpaceLimited > *:last-child) {
  margin-bottom: 25px;
}

:local(.scaffoldingModal) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
}

:local(.pageImage){
  width: 80%;
  margin: auto;
  padding: 2%;
  max-width: var(--app-view-s, 768px);
}

:local(.messageWrapper){
  margin: 20px 0;
}

:local(.pricingLoader) {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0;
}

.blade-message ul {
  list-style: circle;
  padding-left: 15px;
  margin-left: 5px;
}

.blade-message ul li {
  margin-bottom: 7px;
}

.blade-message ul li .blade-link {
  color: var(--app-warning-on, #fff);
}
