:local(.productOverview) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
  padding-top: var(--space-large);
}

:local(.productOverview_description) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
  padding-bottom: var(--space-default);
}

:local(.productOverview_action) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
}

:local(.productOverview_action_trimDivider) {
  border-top: 1px solid var(--surface-trim);
  border-bottom: 1px solid var(--surface-trim);
}

:local(.productOverview_smallPrint) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}
