:local(.base_surface) {
  width: 100%;
  background-color: var(--surface, white);
  box-shadow: var(--surface-depth);
  border-radius: var(--surface-corners, 3px);
}

:local(.base_surface_isScrollable) {
  overflow-y: auto;
}

:local(.surface) {
  width: 100%;
  background-color: transparent;
}

:local(.surface_isFullHeight) {
  height: 100%;
}

:local(.surface_isAside) {
  background-color: var(--background-aside, #f7f7f7);
  color: var(--surface-aside-on);
}

:local(.surface_titleBar) {
  composes: space-row from global;
  min-height: 50px;
}

:local(.surface_titleBar_trimDivider) {
  border-bottom: 1px solid var(--surface-trim, #efefef);
}

:local(.surface_titleBar_title) {
  composes: space-col from global;
  padding: var(--space-large, 15px);
  justify-content: center;
  flex-grow: 1;
}

:local(.borderless_button_titleBar) {
  justify-content: center;
  flex-grow: 1;
}

:local(.surface_titleBar_description) {
  margin-top: 7px;
}

:local(.surface_shouldRespectViewWidth.surface_layerCombined
    > .surface_titleBar
    > .surface_titleBar_title) {
  padding: var(--space-large, 15px) 0;
}

:local(.surface_titleBar_actions) {
  composes: space-row from global;
}

:local(.surface_titleBar_actions_action) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  border-left: 1px solid var(--surface-trim, #efefef);
  cursor: pointer;
}

:local(.surface_titleBar_actions_action:last-child) {
  border-top-right-radius: var(--surface-corners);
}

:local(.surface_titleBar_actions_action:hover) {
  background-color: var(--trigger-hover);
  color: var(--trigger-on);
  transition: background-color var(--app-transition),
    color var(--app-transition);
}

:local(.surface_content:last-child) {
  border-bottom-left-radius: var(--surface-corners);
  border-bottom-right-radius: var(--surface-corners);
}

:local(.surface_shouldRespectViewWidth.surface_layerCombined.surface_isMain
    > .surface_content
    > *) {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

:local(.base_surface.surface_shouldRespectViewWidth.surface_layerCombined) {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

:local(.borderless_button) {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  color: color var(--primary-aside);
}
