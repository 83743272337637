:local(.addressInternational_display) {
  compose: space-col from global;
  width: 100%;
}
:local(.addressInternational_wrapper) {
  background: var(--surface);
  border: var(--input-border-thickness, 1px) solid var(--input);
  border-radius: var(--input-corners);
  padding: 15px;
  min-height: var(--input-height, 60px);
  width: 100%;
  margin-bottom: 15px;
}

:local .addressInternational_wrapper > span {
  display: block;
}

:local(.addressInternational_link) {
  font-size: 12px;
}
