.quickquoteContent {
  display: flex;
}

.quickquoteOverview {
  width: 40%!important;
  margin-right: 20px;
}

.quickquoteData {
  width: 60%;
}

.quickquoteData > * {
  margin-bottom: 20px;
}
