:local(.callToActionSet) {
  composes: space-col from global;
  composes: center from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
  flex-grow: 1;
  width: 100%;
}

:local(.callToActionSet_isVertical h5) {
  align-self: flex-start;
}

:local(.callToActionSet_title) {
  font-size: 21px;
  font-weight: 300;
  opacity: 0.8;
}

:local(.callToActionSet_isCustom) {
  padding: 0 !important;
}

:local(.callToActionSet_isHorizontal h5) {
  align-self: center;
}

:local(.callToActionSet_isVertical_wrap) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  align-self: center;
}

:local(.callToActionSet_isHorizontal_wrap) {
  composes: space-row from global;
  align-self: space-between;
}

:local(.callToActionSet_wrap) {
  composes: center-horizontal from global;
  flex-wrap: wrap;
  flex-shrink: 1;
  width: 100%;
}
