:local(.quoteCard) {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  align-self: flex-start;
}

:local(.quoteCard_content) {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
  margin: var(--space-default) 0;
}

:local(.quoteCard_footer) {
  composes: space-row from global;
  composes: base from global;
  composes: frame from global;
}

:local(.quoteCard_footer_isClosed) {
  height: 25px;
}
