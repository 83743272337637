:local(.badgeActive) {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  padding: 0;
  height: 22px;
}

:local(.badgeActive > label) {
  font-size: 8px;
  color: #389e0d;
}

:local(.badgeLapsed) {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 0 2px;
  height: 22px;
}

:local(.badgeLapsed > label) {
  font-size: 8px;
  color: rgba(0, 0, 0, 0.85);
}

:local(.badgeCancelled) {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
  padding: 0 2px;
  height: 22px;
}

:local(.badgeCancelled > label) {
  font-size: 8px;
  color: #cf1322;
}
