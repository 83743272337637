:local(.callToAction) {
  composes: space-row from global;
  composes: center-vertical from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
  flex-grow: 1;
  width: 100%;
  background-color: var(--surface, white);
  color: var(--surface-on, black);
  border-radius: var(--button-corners, 3px);
  transition: background-color var(--app-transition),
    color var(--app-transition);
  box-shadow: var(--surface-depth);
  position: relative;
}

:local(.callToAction_mobile) {
  padding-bottom: 60px !important;
}

:local(.callToAction_image) {
  max-width: 58px;
  width: 100%;
  flex-shrink: 0;
}

:local(.callToAction_subTitle_noreg) {
  font-size: 16px !important;
  color: #292e39;
  font-weight: 700;
}

:local(.callToAction_titles) {
  composes: space-col from global;
  composes: small from global;
  composes: inside from global;
  flex-grow: 1;
}

:local(.callToAction_subTitle) {
  font-size: 13px;
}

:local(.callToAction_button_mobile) {
  width: 100%;
  max-width: 100% !important;
  bottom: 0;
  left: 0;
  margin-bottom: 0 !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

:local(.callToAction_button) {
  max-width: 145px;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 300;
  font-size: 16px;
}

:local(.gold) {
  composes: h6 from global;
  color: orange;
}
