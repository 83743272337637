:local(.tooltip) {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
  max-width: 380px;
  opacity: 1;
}

:local(.tooltip *) {
  color: #000 !important;
  text-align: left;
}

:local(.tooltipWrapper) {
  cursor: pointer;
}
