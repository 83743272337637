:local(.wrap_callToAction) {
  display: flex;
  flex-grow: 1;
  flex-basis: 330px;
  justify-content: center;
}

:local(.wrap_callToAction:nth-child(even) .callToAction) {
  background-color: var(--primary, black);
}

:local(.callToAction) {
  composes: space-col from global;
  composes: center-horizontal from global;
  color: var(--trigger-on, white);
  background-color: var(--trigger, orange);
  width: 100%;
  height: 370px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  box-shadow: var(--surface-depth);
  border-radius: var(--surface-corners, 3px);
  cursor: pointer;
  text-align: center;
  justify-content: space-between;
  transition: all var(--app-transition);
}

:local(.callToAction img) {
  width: 100px;
  flex-shrink: 0;
}

:local(.callToAction:hover) {
  transform: var(--transform-hover);
  transition: all var(--app-transition);
}

:local(.callToAction_image) {
  composes: space-row from global;
  composes: center from global;
  width: 100%;
  height: 150px;
}

:local(.callToAction_image_icon) {
  font-size: 21px;
}

:local(.callToAction_content) {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
  composes: frame from global;
  composes: center-horizontal from global;
  width: 100%;
  height: 140px;
}

:local(.callToAction_content h2) {
  color: var(--trigger-on, white);
}

:local(.callToAction_content_subtitle) {
  color: var(--trigger-on, white);
}

:local(.callToAction_content_subtitle h1) {
  color: var(--trigger-on, white);
}

:local(.callToAction_button) {
  composes: space-row from global;
  composes: center from global;
  width: 100%;
  height: 60px;
  background-color: rgba(0,0,0,0.08);
  border-bottom-right-radius: var(--surface-corners, 3px);
  border-bottom-left-radius: var(--surface-corners, 3px);
  font-weight: 700;
}

:local(.callToAction_button_icon) {
  color: var(--trigger, orange);
  font-size: 25px;
  transform: var(--transform-hover);
  transition: all var(--app-transition);
}
