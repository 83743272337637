:local(.policyListQuote) {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

:local(.policyListQuote_content) {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 120px;
}

:local(.policyListQuote_content_mobile) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}

:local(.policyListQuote_content_empty) {
  justify-content: center;
}

:local(.policyListQuote_line) {
  font-size: 16px;
}

:local(.policyListQuote_line_mobile) {
  padding-bottom: 15px;
}
:local(.policyListQuote_button) {
  max-width: 500px;
  background-color: var(--primary);
  color: #fff;
  font-weight: 500;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
