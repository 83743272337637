:local(.appBarContent_right_phoneNumber) {
  composes: space-row from global;
  align-items: center;
  height: calc(var(--app-header-height) / 3);
  flex-grow: 0;
  padding-right: 15px;
}

:local(.appBarContent_right_phoneNumber_icon) {
  font-size: 47px !important;
  padding: 13px 1px 17px 15px;
}

:local(.appBarContent_right_phoneNumber_link) {
  font-weight: 700;
  font-size: 16px;
  text-decoration: none !important;
  color: var(--app-bar-on, white);
}

:local(.appBarContent_right_phoneNumber:hover) {
  color: var(--app-bar-on-hover, var(--trigger-hover));
}

:local(.appBarContent_right_phoneNumber:hover
    .appBarContent_right_phoneNumber_link) {
  color:  var(--app-bar-on-hover, var(--trigger-hover));
}
