:local(.setupDirectDebit) {

}

:local(.setupDirectDebit_inputs) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.setupDirectDebit_isSpacious .setupDirectDebit_inputs_line) {
  flex-wrap: nowrap;
}

:local(.setupDirectDebit_isSpaceLimited .setupDirectDebit_inputs_line) {
  flex-wrap: wrap;
}

:local(.setupDirectDebit_inputs_line) {
  composes: space-row from global;
  composes: large from global;
  composes: inside from global;
}

:local(.setupDirectDebit_footer) {
  composes: space-row from global;
  composes: base from global;
  composes: frame from global;
  composes: inside from global;
  align-items: center;
}

:local(.setupDirectDebit_footer_logo) {
  width: 50px;
  align-self: center;
}

:local(.setupDirectDebit_footer_moreDetails) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}
