.bladePriceDisplay {
  padding-bottom: 0;
  min-height: auto;
}

:local(.priceDisplay) {
  composes: space-col from global;
  composes: center from global;
  composes: small from global;
  composes: inside from global;
  color: var(--display-on);
  height: var(--price-display-height, 150px);
}

:local(.priceDisplay > *) {
  color: var(--display-on);
}

:local(.priceDisplayCompact) {
  background: var(--surface);
}

:local(.priceDisplayCompact > *) {
  color: var(--surface-on);
}

:local(.priceDisplay_content) {
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
  align-items: center;
}

:local(.priceDisplay_content_alternatePrice) {
  font-size: 20px;
}

:local(.priceDisplay_content_smallPrint) {
  composes: space-col from global;
  align-items: center;
  text-align: center;
  padding: var(--space-small, 10px) var(--space-default, 15px);
}

:local(.priceDisplayCompact_smallPrint) {
  padding-top: var(--space-small, 5px);
}

:local(.priceDisplayCompact_smallPrint span) {
  display: inline-block;
  padding: 0 1ch 0 0;
}

:local(.priceDisplayCompact_smallPrint *) {
  color: var(--surface-on);
  opacity: 0.7;
}

:local(.priceDisplay_content_smallPrint *) {
  color: var(--display-on);
  opacity: 0.7;
}

:local(.priceDisplay_label) {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0 !important;
}

:local(.priceDisplay_priceBreakdown) {
  composes: space-row from global;
  align-items: baseline;
  margin-bottom: 0 !important;
}

:local(.priceDisplay_priceBreakdown_change) {
  font-size: 72px;
  font-weight: 900;
}

:local(.priceDisplay_priceBreakdown_major) {
  font-size: 72px;
  line-height: 72px;
  font-weight: 900;
  transition: font-size var(--app-transition);
}

:local(.priceDisplayCompact_priceBreakdown_major) {
  font-size: 44px;
  font-weight: 900;
}

:local(.priceDisplay_priceBreakdown_major_isSticky) {
  font-size: 52px;
  transition: font-size var(--app-transition);
}

:local(.priceDisplay_priceBreakdown_minor) {
  font-size: 20px;
  font-weight: 700;
}

:local(.priceDisplay_priceBreakdown_paymentPeriod) {
  font-size: 20px;
  font-weight: 700;
  padding-left: var(--space-default);
}

:local(.priceDisplayCompact_priceBreakdown_paymentPeriod) {
  font-size: 20px;
  font-weight: 700;
  padding-left: 5px;
}

:local(.display_compact) {
  padding: 0px;
  min-height: 0px;
}

:local(.display_wrapper) {
  width: 100%;
}

:local(.priceDisplayCompact_label) {
  font-size: 16px;
  font-weight: 700;
}
