:local(.icon_isClickable) {
  cursor: pointer;
}

:local(.icon_isClickable_style) {
  color: var(--trigger);
}

:local(.icon_isClickable_style:hover) {
  color: var(--trigger-hover);
  transition: color var(--app-transition);
}
