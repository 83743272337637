:local(.vriMissingData) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.vriMissingData_description) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
  padding-bottom: var(--space-default);
}

:local(.vriMissingData_callToAction) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  border-top: 1px solid var(--surface-trim);
  border-bottom: 1px solid var(--surface-trim);
}
