:local(.appBar) {
  composes: space-row from global;
  composes: center-horizontal from global;
  background-color: var(--app-bar, black);
  color: var(--app-bar-on, white);
  height: var(--app-header-height, 80px);
}

:local(.appBar_view) {
  width: 100%;
  composes: space-col from global;
}

:local(.appBar_phone) {
  height: 30px;
  padding: 0 var(--space-large);
  display: flex;
  justify-content: flex-end;
  /* background-color: var(--surface-trim); */
}

:local(.appBarContent) {
  composes: space-row from global;
  composes: center-vertical from global;
  composes: large from global;
  composes: frame from global;
  justify-content: space-between;
  height: inherit;
}

:local(.appBarContent > *) {
  color: var(--app-bar-on, white);
}

:local(.appBarContent svg) {
  font-size: 55px;
  padding: 15px;
}

:local(.appBarContent_right) {
  composes: space-col from global;
  width: 100%;
  align-items: flex-end;
}

:local(.appBarContent_right_render) {
  height: calc(var(--app-header-height) / 3);
  flex-grow: 0;
  overflow: hidden;
  align-items: center;
  display: flex;
}
