:local(.date_container) {
  width: 100%;
  display: flex;
}

:local(.date_input_container) {
  width: 100%;
  padding-right: 4px;
}

:local(.date_input_container__last) {
  padding-right: 0;
}
