:local(.oneByOne) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: center-vertical from global;
  height: 100%;
}

:local(.oneByOne_frame) {
  z-index: 9;
}

:local(.oneByOne_navLink) {
  align-self: flex-start;
}

:local(.oneByOne_question) {
  composes: space-row from global;
  composes: center from global;
  position: relative;
  width: 100%;
}

:local(.oneByOne_question_isDisabled) {
  composes: disabled from global;
}

:local(.oneByOne_question_isHidden) {
  composes: invisible from global;
}

:local(.oneByOne_question_input) {
  composes: space-row from global;
  composes: center from global;
  flex-grow: 1;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  transition: 'height 0.1s ease-in'
}

:local(.oneByOne_question_input_wrap) {
  width: 100%;
  position: absolute;
}

:local(.oneByOne_question_navButton) {
  composes: space-row from global;
  composes: center from global;
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--trigger, orange);
  color: var(--trigger-on, white);
  cursor: pointer;
  transition: all var(--app-transition);
  box-shadow: var(--surface-depth);
}

:local(.oneByOne_question_navButton:first-child) {
  margin-right: 10px;
  left: -25px;
}

:local(.oneByOne_question_navButton:last-child) {
  margin-left: 10px;
  right: -25px;
}

:local(.oneByOne_question_navButton:hover) {
  transform: var(--transform-hover-transform);
  transition: transform var(--app-transition);
}

:local(.oneByOne_submit) {
  width: 100%;
}
