:local(.scaffolding) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
  flex: 1;
}

:local(.scaffoldingModal) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
}

:local(.buttonWrapper) {
  composes: space-row from global;
  composes: large from global;
  composes: inside from global;
}
