:local(.boundary_frame) {
  width: 100%;
  height: 100%;
}

:local(.boundary_notification) {
  width: 300px;
  top: 45px;
  margin: 0!important;
  right: 45px;
}
