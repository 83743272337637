:local(.quickQuote) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.quickQuote_singleColumn) {
  flex-wrap: wrap;
}

:local(.quickQuote_doubleColumn) {
  flex-wrap: nowrap;
}

:local(.quickQuote_singleColumn .quickQuote_card) {
  width: 100%;
}

:local(.quickQuote_doubleColumn .quickQuote_card) {
  max-width: 400px;
}

:local(.quickQuote_form) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  flex: 1;
}

:local(.quickQuote_form_controls) {
  composes: space-row from global;
  composes: large from global;
  composes: inside from global;
  width: 100%;
}
