:local(.ask_controls) {
  composes: large from global;
  composes: inside from global;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

:local(.ask_variant_inline) {
}

:local(.ask_variant_fixed) {
  background: var(--surface);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--app-footer-height, 120px);
  z-index: 99;
}

:local(.ask_controls_wrap) {
  display: flex;
  align-items: flex-start;
}

:local(.ask_controls_wrap_mobile) {
  flex-direction: column;
}

:local(.ask_controls_wrap_mobile .ask_controls_right) {
  margin-top: var(--space-default);
}

:local(.ask_controls_left) {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

:local(.ask_controls_right) {
  display: flex;
  align-items: center;
}

:local(.ask_controls_right a) {
  font-size: var(--trigger-font-size, 24px);
  font-weight: var(--trigger-font-weight, bold);
}

:local(.ask_controls_right *) {
  padding-left: var(--space-large, 14px);
  padding-right: var(--space-large, 14px);
  margin-right: var(--space-small, 5px);
}

:local(.ask_controls_right *:last-child) {
  margin-right: 0px;
}

:local(.ask_controls_isSpaceLimited) {
  flex-direction: column;
}

:local(.ask_controls_isSpaceLimited > *:last-child) {
  margin-bottom: 25px;
}
