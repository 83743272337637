:local(.dateSelects) {
  composes: space-row from global;
  composes: small from global;
  composes: inside from global;
}

:local(.datepickerShellOveride) {
  width: 208px;
  width: fit-content;
  width: -moz-fit-content;
}

:local(.datepickerShellOveride:focus) {
  outline: none;
  border-bottom: 2px solid var(--highlight);
  transition: color var(--app-transition), border-bottom var(--app-transition);
}

:local(.centerDateInputs) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:local(.overideDatebox) {
  height: calc(var(--input-height) - 2px);
  box-sizing: border-box;
  border: none;
  text-align: center;
  border-radius: 0.3rem;
}

:local(.seperators) {
  font-weight: 900;
  display: inline-block;
}

:local(.yearBox) {
  composes: overideDatebox;
  margin-left: 5px;
  width: 63px;
}

:local(.monthbox) {
  composes: overideDatebox;
  margin-left: 5px;
  width: 51px;
}

:local(.daybox) {
  composes: overideDatebox;
  width: 48px;
}

:local(.errorMessage) {
  color: var(--app-error);
}
