:local(.checkout_frame) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.checkout_frame_voucher) {
  padding: var(--space-large, 15px);
}

:local(.checkout_frame_isSpaceLimited) {
  composes: small from global;
  flex-wrap: wrap;
}

:local(.checkout_frame_isSpacious) {
  composes: large from global;
  flex-wrap: nowrap;
}

:local(.checkout_frameForError) {
  composes: space-row from global;
  composes: frame from global;
}

:local(.checkout_frame_content) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  flex-grow: 1;
}

:local(.checkout_frame_isSpaceLimited .checkout_frame_content) {
  order: 1;
}

:local(.checkout_frame_isSpacious .checkout_frame_content) {
  order: 0;
}

:local(.checkout_frame_receipt) {
  composes: space-row from global;
}

:local(.checkout_actions) {
  composes: space-row from global;
  composes: large from global;
  composes: inside from global;
}

:local(.checkout_actions_back) {
  width: auto;
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
}

:local(.checkout_frame_isSpaceLimited .checkout_frame_receipt) {
  flex-basis: 100%;
  order: 0;
  margin-bottom: 25px;
}

:local(.checkout_frame_isSpacious .checkout_frame_receipt) {
  min-width: 300px;
  max-width: 300px;
  order: 1;
}
