:local(.buttonSocialAuth) {
  width: 100%;
  height: 60px;
  cursor: pointer;
  border-radius: var(--button-corners, 3px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  font-size: 16px;
}

:local(.buttonSocialAuth_icon) {
  align-self: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

:local(.buttonSocialAuth_logo) {
  width: 30px;
  height: 30px;
}

:local(.buttonSocialAuth_variant_google) {
  background-color: white;
  color: rgba(0,0,0,0.54);
  border: 1px solid #e6e6e6;
}
