:local(.lpocFooter) {
  width: 100%;
  background: #f5f5f5;
  color: #ae9f91;
  display: flex;
  justify-content: center;
}

:local(.lpocFooter_content) {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 25px;
}

:local(.lpocFooter_content_links) {
  display: flex;
}

@media only screen and (max-width: 750px) {
  :local(.lpocFooter_content_links) {
    flex-direction: column;
  }
  :local(.lpocFooter_content_links li:after) {
    content: ''!important;
  }
}

:local(.lpocFooter_content_links li:after) {
  content: '|';
  margin: 0 10px;
}

:local(.lpocFooter_content_links li:last-child:after) {
  content: '';
}

:local(.lpocFooter_content_links a) {
  color: #ae9f91;
  cursor: pointer;
}

:local(.lpocFooter_content_regStatement) {
  max-width: 60%;
  font-size: 12px;
  opacity: 0.8;
  margin-top: 25px;
}

:local(.lpocFooter_content_regStatement p) {
  margin-bottom: 2px!important;
}