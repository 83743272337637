:local(.scaffold) {
  width: 100%;
  composes: space-col from global;
}

:local(.view) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.renewalDashboard) {
  border-radius: var(--surface-corners);
  background: var(--surface);
  overflow: hidden;
}

:local(.renewalDashboard_alreadyConverted) {
  border-radius: var(--surface-corners);
  background: var(--surface);
  overflow: hidden;
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.renewalActions) {
  composes: space-col from global;
}

:local(.renewalActions_primary) {
  composes: space-col from global;
  composes: large from global;
  composes: frame from global;
  padding-bottom: 0 !important;
}

:local(.renewalActions_secondary) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
  composes: inside from global;
}

:local(.renewalDisclaimer) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}

:local(.titleNoSpaceBottom) {
  padding-bottom: 0;
}
