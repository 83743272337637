:local(.scaffold) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  width: 100%;
}

:local(.scaffold_paddingTop) {
  padding-top: 25px;
}

:local(.carDetails) {
  composes: base from global;
  composes: inside from global;
  align-items: flex-end;
}

:local(.carDetails_isSpacious) {
  composes: space-row from global;
}

:local(.carDetails_isSpaceLimited) {
  composes: space-col from global;
}

:local(.registration_input) {
  text-transform: uppercase;
}

:local(.registration_input::placeholder) {
  text-transform: none;
}
:local(.registrationFlag_wrapper) {
  composes: base from global;
  padding-top: 25px;
}

:local(.registrationFlag_wrapper_col) {
  composes: space-col from global;
}

:local(.registrationFlag_wrapper_row) {
  composes: space-row from global;
}

:local(.registrationFlag_checkbox_wrapper) {
  width: 100%;
}

:local(.registrationFlag_checkbox_wrapper_fixed_width) {
  width: 30%;
  margin-right: 15px;
}

:local(.registrationFlag_checkbox label) {
  margin-left: 15px;
  font-weight: 700;
}

:local(.registrationFlag_message) {
  width: 100%;
  margin-top: 15px;
  font-weight: 700;
}

:local(.registrationFlag_message_fixed_width) {
  margin-top: 0;
  width: calc(70% - 15px);
}
