:local(.daterange_wrapper) {
  position: relative;
  width: 100%;
}

:local(.daterange_input) {
  padding-left: 35px;
  width: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 400px) {
  :local(.daterange_input) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 50px;
  }
}

:local(.daterange_icon) {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 11px;
  transform: translate(0, -50%);
}
