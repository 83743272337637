:local(.listDocuments) {
  background: var(--surface);
  border: var(--input-border-thickness, 1px) solid var(--input);
  border-radius: var(--input-corners);
  width: 100%;
}

:local(.listDocumentsTitle) {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  padding: 0;
  padding: 15px;
}

:local(.listDocumentsGroupTitle) {
  font-weight: 400;
}

:local(.listDocumentsContent) {
  border-top: var(--input-border-thickness, 1px) solid var(--input);
}

:local(.listDocumentsElem) {
  padding: 25px 15px;
  background-color: var(--surface, white);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

:local(.listDocumentsElemInside) {
  padding-left: 30px;
}


:local(.listDocumentsElemTitleWrapper) {
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

:local(.listDocumentsElemSubTitle) {
  font-size: 12px;
}

:local(.listDocumentsElemTitleIcon) {
  width: 100px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

:local(.listDocumentsElemUrl) {
  display: flex;
}

:local(.listDocumentsElemIcon) {
  margin-left: 10px;
}

:local(.listDocumentsElem:nth-of-type(even)) {
  background-color: #f6f6f6;
}

:local(.listDocumentsEmpty) {
  padding: 20px;
  text-align: center;
  opacity: 0.5;
}

:local(.listDocumentsElem:last-child) {
  border-top: 0;
  border-bottom-left-radius: var(--input-corners);
  border-bottom-right-radius: var(--input-corners);
}
