:local(.declined) {
  composes: space-col from global;
  composes: large from global;
  composes: inside from global;
  composes: frame from global;
  padding-top: var(--space-large);
}

:local(.declined_secondTitle) {
  padding: var(--space-small, 10px) var(--space-large, 15px) var(--space-small, 10px) var(--space-large, 15px);
}

:local(.declined_reasons) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}

:local(.declined_help) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}

:local(.declined_trigger) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}
