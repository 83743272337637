:local(.mfModal) {
  position: fixed;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

}

.surfaceOverride::-webkit-scrollbar {
  display: none;
}
@media screen and (min-width: 1024px) {
:local(.mfmodel_surface_scaffold){
  position: absolute;
    right: 5px;
}
}

:local(.modal) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInFromNone 0.6s ease-out;
}


:local(.modal_closeTrigger) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  cursor: pointer;
}

:local(.modal_surface_scaffold) {
  width: 70%;
  height: auto;
  max-height: 70%;
  display: flex;
}

:local(.mfmodel_surface_scaffold) {
  width: 92%;
  height: 98%;
  bottom:0;
  display: flex;
  animation-name: slideup;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

@keyframes slideup {
  0%   {top:800px;}
  100%  { top: 5px;}
}


:local(.surfaceOverride) {
  border-radius: 25px 25px 0 0;
  padding: 30px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:local(.modal_surface_content) {
  composes: space-col from global;
}

:local(.modal_surface_content_withFrame) {
  composes: large from global;
  composes: frame from global;
}

:local(.notch){
  height: 5px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 2%;
  align-self: center;
  left: 46%;
}

@keyframes fadeInFromNone {
  0% {
    display: flex;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}
