:local(.message) {
  composes: space-row from global;
  width: 100%;
  height: auto;
  border-radius: var(--surface-corners, 3px);
}

:local(.message_isFlush) {
  border-radius: 0;
}

:local(.message_isOverlayed) {
  height: 100%;
}

:local(.message_icon) {
  width: 50px;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:local(.message_content) {
  composes: space-col from global;
  flex-grow: 1;
  position: relative;
}

:local(.message_content_message) {
  composes: space-row from global;
  flex-grow: 1;
  min-height: 50px;
  align-items: center;
  padding: var(--space-default, 15px);
  background-color: rgba(250, 250, 250, 0.5);
  padding-right: 50px;
}

:local(.message_content_message p) {
  font-weight: bold;
}

:local(.message_isDismissable .message_content_message p) {
  margin-right: 30px;
}

:local(.message_content_detail) {
  composes: space-col from global;
  composes: base from global;
  composes: frame from global;
  flex-grow: 1;
  padding: var(--space-default, 15px) var(--space-default, 15px) 0;
  background-color: rgba(250, 250, 250, 0.6);
}

:local(.message_content_dismiss) {
  position: absolute;
  right: var(--space-default);
  top: var(--space-default);
  background-color: rgba(250, 250, 250, 0.4);
  cursor: pointer;
  text-align: left;
  padding: var(--space-small);
  border-radius: var(--surface-corners);
  text-transform: uppercase;
  font-weight: 500;
}

:local(.message_content_dismiss:hover) {
  background-color: rgba(250, 250, 250, 0.8);
}

:local(.message_success) {
  background-color: var(--app-success, green);
  color: var(--app-success-on, white);
}

:local(.message_success .message_content_dismiss) {
  color: var(--app-success-on, white);
}

:local(.message_error) {
  background-color: var(--app-error, red);
  color: var(--app-error-on, white);
}

:local(.message_error .message_content_dismiss) {
  color: var(--app-error-on, white);
}

:local(.message_info) {
  background-color: var(--app-info, blue);
  color: var(--app-info-on, white);
}

:local(.message_info .message_content_dismiss) {
  color: var(--app-info-on, white);
}

:local(.message_info .message_content_message) {
  background-color: rgba(250, 250, 250, 0.8);
}

:local(.message_info .message_content_detail) {
  background-color: rgba(250, 250, 250, 0.8);
}

:local(.message_warning) {
  background-color: var(--app-warning, orange);
  color: var(--app-warning-on, white);
}

:local(.message_warning .message_content_dismiss) {
  color: var(--app-warning-on, white);
}
