:local(.cancelAgentRequired) {
  composes: space-column from global;
  composes: large from global;
  composes: frame from global;
  height: auto;
  align-self: auto;
  padding-top: var(--space-large, 25px);
}

:local(.cancelAgentRequired_content) {
  composes: space-row from global;
  composes: large from global;
  composes: frame from global;
}
