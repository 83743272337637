:local(.absoluteContainer) {
    position: absolute;
    padding: 0.5rem;
    overflow: hidden;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 0;
    max-width: 38rem;
    width: 95vw;
}

:local(.absoluteContainer)::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: var(--trigger-hover);
    width: min(111px, 19.8vw);
    height: min(111px, 19.8vw);
    z-index: -1;
    clip-path: polygon(0 0, 20% 0, 100% 80%, 100% 100%, 0 100%);
}

:local(.absoluteContainer)::after {
    content: "";
    position: absolute;
    inset: 0.5rem;
    box-shadow: 2px 2px 5px var(--background-trim);
    z-index: -2;
    border-radius: 15px;
}

:local(.pricesContainer) {
    display: flex;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    max-width: 100%;
}

:local(.priceDisplay) {
    padding: min(0.75rem, 2.8vw) 0.75vw min(0.75rem, 2vw) 0.75vw;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #ffc629;
    text-align: center;
    font-size: min(max(0.8rem, 3vw), 1rem);
}

:local(.priceDisplay:first-child) {
    background: #fff;
}

:local(.planType) {
    font-size: min(1.2rem, 4vw);
    margin-bottom: min(0.75rem, 1.5vw);
}

:local(.price) {
    font-size: min(2.5rem, 6.5vw);
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 0.2rem;
}

:local(.asterisk) {
    font-size: min(1.5rem, 4vw);
    vertical-align: top;
    line-height: 120%;
}

:local(.subText) {
    line-height: 110%;
    font-size: min(max(0.7rem, 3vw), 1rem);
}

:local(.flash) {
    position: absolute;
    padding: 0.2rem 0;
    color: #fff;
    background: var(--primary);
    font-weight: 500;
    line-height: 110%;
    transform: translate(50%, -50%) rotate(45deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    width: 200px;
    top: min(2.5rem, 7vw);
    right: min(2.5rem, 7vw);
    height: min(auto, 8vw);
    font-size: min(3vw, 1rem);
}

.stackedPrice :local(.absoluteContainer) {
    width: auto;
    transform: translate(-50%, -45%);
}

.stackedPrice :local(.absoluteContainer)::after {
    box-shadow: 2px 2px 5px 2px var(--background-trim);
}

.stackedPrice :local(.pricesContainer) {
    flex-direction: column-reverse;
}

.stackedPrice :local(.priceDisplay) {
    padding: min(0.75rem, 2.8vw) 3.5rem min(0.75rem, 2vw) 3.5rem;
}

.stackedPrice :local(.priceDisplay) :local(.price) {
    font-size: min(2.5rem, 10vw);
}

@media screen and (max-width: 600px) {
    :local(.absoluteContainer) {
        padding: 0.3rem;
        width: 99vw;
    }
}
