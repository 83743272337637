:local(.scaffold) {
  position: relative;
  width: 100%;
}

:local(.stripeElement_scaffold) {
  position: absolute;
  width: 100%;
  height: var(--input-height, 40px);
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
}

:local(.stripeElement_scaffold > label) {
  margin-top: 10px;
}

:local(.stripeElement_wrapper) {
  width: 100%;
  padding: 10px;
  border: 0;
  outline: 0;
  border-radius: var(--input-corners);
}

:local(.stripeElement_base) {
  color: var(--input-on);
  font-size: var(--font-size-body);
  font-family: var(--main-font);
}

:local(.stripeElement_base::placeholder) {
  color: var(--input-aside);
}

:local(.stripeElement_invalid) {
  color: var(--app-error);
}

:local(.stripeElement_error) {
  width: 100%;
  margin-top: 10px;
  display: block;
  color: var(--app-error);
}
