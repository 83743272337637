:local(.price) {
  composes: space-col from global;
  composes: center from global;
  width: 100%;
  height: 100px;
  background-color: var(--display, honeydew);
  position: relative;
}

:local(.price_summary) {
  composes: space-row from global;
  align-items: baseline;
}

:local(.price_summary label) {
  color: var(--display-on, black);
}

:local(.price_summary_symbol) {
  font-size: 50px;
  font-weight: 900;
}

:local(.price_summary_major) {
  font-size: 60px;
  font-weight: 900;
}

:local(.price_summary_minor) {
  font-size: 30px;
  font-weight: 900;
}

:local(.price_badge) {
  position: absolute;
  bottom: -12px;
}
